import {useEffect} from "react";
import {useAuthContext} from "../../context/AuthContext";
import {useAddressContext} from "../../context/AddressContext";
import {useModalContext} from "../../context/ModalContext";
import {
    HREF_PAGE_HOME,
    HREF_PAGE_PATIENT_ADDRESSES_EDIT,
    MODAL_TYPE,
    STANDARD_MODAL_TYPE,
    USER_TYPE_PATIENT
} from "../../models/constants";
import locales_es from "../../locales/es";
import Loading from "../../components/loading";
import EmptyAddressList from "../../components/UserAddresses/EmptyAddressList";
import Title from "../../components/UserAddresses/Title";
import AddUserAddressButton from "../../components/UserAddresses/AddUserAddressButton";
import AddressItem from "../../components/UserAddresses/AddressItem";

const UserAddressesPage = () => {

    const {userType, isLoadingAuth} = useAuthContext();
    const {addresses, isLoadingAddress, deleteAddress} = useAddressContext();
    const {dispatch} = useModalContext();

    const goToEditAddress = (addressId) => {
        window.location.href = `${HREF_PAGE_PATIENT_ADDRESSES_EDIT}/${addressId}`;
    };

    const handleDeleteAddress = async (addressId) => {
        dispatch({
            type: "OPEN_MODAL",
            modalName: "handleDeleteAddress",
            modalType: MODAL_TYPE.STANDARD,
            payload: {
                modalId: "handleDeleteAddress",
                title: locales_es.userAddresses.modalDeleteAddressMessage,
                hideCloseButton: false,
                actions: [
                    {
                        title: locales_es.userAddresses.modalConfirmDeleteButton,
                        className: "btn btn-primary",
                        closeOnClick: true,
                        onClick: async () => {
                            deleteAddress(addressId);
                        }
                    },
                    {
                        title: locales_es.cancel,
                        className: "btn btn-secondary",
                        type: STANDARD_MODAL_TYPE.CANCEL,
                        closeOnClick: true
                    }
                ],
                children: (
                    <>
                        {locales_es.confirmActionModal.subtitle}.
                    </>
                )
            }
        });
    };

    useEffect(() => {
        if (!isLoadingAuth) {
            if (userType !== USER_TYPE_PATIENT) {
                window.location.href = HREF_PAGE_HOME;
            }
        }
    }, [isLoadingAuth, userType]);

    if (isLoadingAddress || isLoadingAuth) {
        return <Loading/>;
    }

    if (!addresses.length) {
        return <EmptyAddressList/>;
    }

    return (<>
        <Title title={locales_es.userAddresses.pageTitle}/>
        <div className="kt-portlet__body">
            <div className="kt-portlet kt-portlet--height-fluid-half kt-widget-14">
                <div className="kt-portlet__body">
                    {addresses.map((address) => (
                        <AddressItem
                            key={address.id}
                            address={address}
                            onEdit={() => goToEditAddress(address.id)}
                            onDelete={() => handleDeleteAddress(address.id)}
                        />
                    ))}
                </div>
            </div>
        </div>
        <AddUserAddressButton/>
    </>);
};

export default UserAddressesPage;
