const Title = ({title}) => {
    return (
        <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title mb-5">{title}</h3>
            </div>
        </div>
    )
}

export default Title;
