import React, {Component} from 'react';
import Loading from "./../../components/loading";
import APIService from './../../modules/apiService';
import Subheader from "../../components/subheader";
import locales_es from "../../locales/es";
import {
  DEFAULT_TIME_ZONE, HREF_PAGE_ADD_PATIENT,
  HREF_PAGE_HOME,
  HREF_PAGE_MEDICS,
  HREF_PAGE_PATIENTS,
  PARSE_TEL_INPUT_ONLY_COUNTRIES,
  USER_TYPE_PATIENT, USER_TYPE_SECRETARY,
} from "../../models/constants";
import Helpers from "../../modules/helpers";
import AuthService from "../../modules/authService";
import Form from "../../components/form";
import DateTimeService from "../../modules/DateTimeService";
import intlTelInput from "intl-tel-input";
import ConfigService from "../../modules/configService";
import TimezoneService from "../../modules/timezoneService";
import AppointmentsList from "../../components/appointmentsList";
import Spinner from "../../components/spinner";
import ViewHelpers from "../../modules/viewHelpers";

export default class AddPatientPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      lastname: '',
      email: '',
      email_repeat: '',
      user_type: USER_TYPE_PATIENT,
      identificationOptions: [],
      genderOptions: [],
      timezoneOptions: [
        {value: 0, label: locales_es.loading},
      ],
      timezone: DEFAULT_TIME_ZONE,
      activeTab: 'editPatient',
      redirect: window.URLSearchParams
        ? new window.URLSearchParams(this.props.location.search).get("redirect") : null,
      slot: window.URLSearchParams
        ? JSON.parse(new window.URLSearchParams(this.props.location.search).get("slot")) : null,
      medic_id: window.URLSearchParams
        ? new window.URLSearchParams(this.props.location.search).get("medic_id") : null,
      patientId: window.URLSearchParams
        ? new window.URLSearchParams(this.props.location.search).get("patientId") : null,
      patientIdToClone: window.URLSearchParams
        ? new window.URLSearchParams(this.props.location.search).get("patientIdToClone") : null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.configService = new ConfigService();
    this.timezoneService = new TimezoneService();
    this.viewHelpers = new ViewHelpers();
  }

  componentDidMount() {

    if (!this.auth.isLoggedUser() || this.auth.getLocalUserType() === USER_TYPE_PATIENT) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    this.api.getIdentificationTypes().then(res => {
      this.setState({
        identificationOptions: res.data,
        identification_type_id: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
    this.api.getGenders().then(res => {
      this.setState({
        genderOptions: res.data,
        gender_id: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.timezoneService.getRemoteParsedTimezones().then(res => {
      this.setState({
        timezoneOptions: res,
        timezone: res.filter(tz => tz.value === DEFAULT_TIME_ZONE)[0]
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
      this.getMedics();
    } else {
      this.loadPatient();
    }
  }

  getMedics() {
    this.configService.getLocalClinicData().then(clinic => {
      this.setState({
        clinicId: clinic.id
      }, () => {
        const params = {clinic_id: this.state.clinicId};
        if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
          params.show_disabled = true;
        }
        this.api.getMedics(params)
          .then((res) => {
            this.setState({
              medics: res.data.map(medic => {
                medic.value = medic.id;
                medic.label = `${medic.lastname} ${medic.name}`;
                return medic;
              })
            }, () => {
              if (this.state.medic_id) {
                const foundMedic = this.state.medics.find(medic => Number(medic.id) === Number(this.state.medic_id));
                this.setState({
                  medic: foundMedic || null,
                }, () => {
                  this.loadPatient();
                });
              } else {
                this.loadPatient();
              }
            });
          }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setState({
            medics: [],
          });
        });
      });
    }).catch(err => {
      console.log(err);
    });
  }

  renderPatient(res) {
    this.setState({
      name: res.data.name,
      lastname: res.data.lastname,
      email: this.viewHelpers.userDataHasData(res.data, 'email'),
      email_repeat: this.viewHelpers.userDataHasData(res.data, 'email'),
      cellphone: res.data.cellphone ?? res.data.user?.cellphone ?? '',
      identification_type_id: res.data.identification_type_id,
      identification: res.data.identification,
      date_of_birth: res.data.date_of_birth ? this.dateTimeService.parseAPIStringToDate(res.data.date_of_birth) : '',
      gender_id: res.data.user?.gender_id || res.data.gender_id,
      /*
      TODO la API no devuelve el timezone del patient
      time_zone: {
        'value': res.data.user?.time_zone,
        'label': this.state.timezoneOptions.filter(tz => tz.value === res.data.user?.time_zone)[0].label,
      },*/
      medic_id: res.data.medic_id || null,
      medic: res.data.medic ? {
        'value': `${res.data.medic?.id}`,
        'label': `${res.data.medic?.lastname} ${res.data.medic?.name}`,
      } : null,
      notes: res.data.notes,
    }, () => {
      this.parseTelInputs();
    })
  }

  loadPatient() {
    if (this.state.patientId || this.state.patientIdToClone) {
      this.api.getPatient(this.state.patientId || this.state.patientIdToClone).then(res => {
        if (this.state.patientIdToClone) {
          delete res.data.id;
          delete res.data.medic;
          delete res.data.medic_id;
        }
        this.renderPatient(res);
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.parseTelInputs();
      });
    } else {
      this.parseTelInputs();
    }
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value, medic_id: value.id});
  };

  parseTelInputs() {

    const itiRegex = /\+\d+/gm;
    if (itiRegex.test(this.state.cellphone)) {
      const cellphone = this.helpers.findDialCodeAndPhoneNumber(this.state.cellphone).phoneNumberWithoutDialCode;
      const countryCode = this.helpers.findDialCodeAndPhoneNumber(this.state.cellphone).countryCode;

      this.setState({
        cellphone,
      }, () => {
        this.processParseTelInputs(countryCode);
      });
    } else {
      this.processParseTelInputs();
    }
  }

  processParseTelInputs(countryCode) {
    // TODO Improve
    setTimeout(() => {
      const input = document.querySelector(".cellphoneInput");
      intlTelInput(input, {
        // any initialisation options go here
        initialCountry: countryCode || 'AR',
        autoPlaceholder: 'off',
        preferredCountries: PARSE_TEL_INPUT_ONLY_COUNTRIES
      });
      this.setLoading(false);
    }, 700);
  }

  validateForm() {
    let errors = 0;
    if (!this.state.name
      || !this.state.lastname
      || !this.state.gender_id
    ) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeAllFormFields);
    }
    if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY && (isNaN(this.state.medic_id) || Number(this.state.medic_id) === 0)) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectAMedicToAddPatient);
    }

    if (this.state.email !== this.state.email_repeat) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.emailsDontMatch);
    }

    if (!this.state.timezone) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.checkTimeonze);
    }

    return !errors;
  }

  send() {
    if (this.validateForm()) {
      this.setLoading(true);

      const objData = JSON.parse(JSON.stringify(this.state));

      const itiFlag = document.querySelector('.iti__selected-flag');
      if (itiFlag && objData.cellphone) {
        const itiRegex = /\+\d+/gm;
        if (itiRegex.test(itiFlag.title)) {
          const prefix = itiFlag.title.match(itiRegex)[0];
          objData.cellphone = prefix + ' ' + (objData.cellphone ?? '');
        }
      }

      objData.date_of_birth = this.dateTimeService.parseStringDateToAPIStringDate(
        this.dateTimeService.parseDateToConventionalAPIString(this.state.date_of_birth)
      );

      objData.time_zone = this.state.timezone.value;

      // limpiemos el request
      delete objData.genderOptions;
      delete objData.identificationOptions;
      delete objData.timezoneOptions;
      delete objData.slot;
      delete objData.medics;
      delete objData.medic;

      if (this.state.patientId) {
        this.api.putPatient(this.state.patientId, objData)
          .then((res) => {
            this.props.showMainModal(locales_es.successModal.title, res.message);
            this.successLoginRedirect();
            this.setLoading(false);
          }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setLoading(false);
        });
      } else {
        this.api.postPatient(objData)
          .then((res) => {
            this.props.showMainModal(locales_es.successModal.title, res.message);
            this.successLoginRedirect();
            this.setLoading(false);
          }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setLoading(false);
        });
      }
    }
  }

  successLoginRedirect() {
    if (this.state.redirect) {
      // window.location.href = this.state.redirect;
      this.props.history.replace(`${this.state.redirect}?slot=${JSON.stringify(this.state.slot)}`);
      return;
    }
    // window.location.href = hrefDashboard;
    this.props.history.replace(HREF_PAGE_MEDICS);
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }
  getPatientAppointments() {
    this.setState({
      appointments: null
    }, () => {
      this.api.getPatientAppointments(this.state.patientId, this.state.medic_id).then(res => {
        this.setState({
          appointments: res.data
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    })
  }

  render() {

    const {timezoneOptions, appointments} = this.state;

    const inputs = [
      {
        label: locales_es.name,
        placeholder: locales_es.name,
        id: 1,
        state: 'name',
        value: this.state.name,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.lastname,
        placeholder: locales_es.lastname,
        id: 2,
        state: 'lastname',
        value: this.state.lastname,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.email_address,
        placeholder: locales_es.email_address,
        id: 3,
        state: 'email',
        value: this.state.email,
        type: 'email',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.repeatEmail,
        placeholder: locales_es.repeatEmail,
        id: 4,
        state: 'email_repeat',
        value: this.state.email_repeat,
        type: 'email',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.phone_number,
        placeholder: locales_es.phone_number_example,
        id: 7,
        state: 'cellphone',
        value: this.state.cellphone,
        type: 'number',
        required: false,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0 clear',
        customClassName: 'cellphoneInput',
      },
      {
        label: locales_es.identificationType,
        placeholder: locales_es.identificationType,
        id: 8,
        state: 'identification_type_id',
        value: this.state.identification_type_id,
        type: 'select',
        required: true,
        options: this.state.identificationOptions,
        wrapperCustomClassName: 'form-group col-4 col-md-3 float-left pl-md-0',
      },
      {
        label: locales_es.identification,
        placeholder: locales_es.number,
        id: 9,
        state: 'identification',
        value: this.state.identification,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-8 col-md-5 float-left pr-md-0',
      },
      {
        label: locales_es.date_of_birth,
        placeholder: locales_es.date_of_birth_mask,
        id: 10,
        state: 'date_of_birth',
        value: this.state.date_of_birth,
        type: 'date',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
        maxDate: new window.Date().getTime(),
      },
      {
        label: locales_es.autoperceivedGender,
        placeholder: locales_es.autoperceivedGender,
        id: 11,
        state: 'gender_id',
        value: this.state.gender_id,
        type: 'select',
        required: true,
        options: this.state.genderOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      /*{
        label: locales_es.timezone,
        placeholder: locales_es.timezone,
        id: 12,
        state: 'timezone',
        value: this.state.timezone,
        type: 'react-select',
        required: true,
        options: this.state.timezoneOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },*/
      {
        label: locales_es.notes,
        placeholder: locales_es.notesPlaceholder,
        id: 13,
        state: 'notes',
        value: this.state.notes,
        type: 'textarea',
        required: false,
        wrapperCustomClassName: 'form-group col clear',
      },
    ];

    if (this.state.medics) {
      inputs.unshift(
        {
          label: locales_es.medic,
          placeholder: locales_es.medic,
          id: 14,
          state: 'medic',
          value: this.state.medic,
          type: 'react-select',
          required: true,
          options: this.state.medics,
          wrapperCustomClassName: 'form-group col-md-6 pl-md-0',
          disabled: this.state.patientId,
        }
      )
    }

    return (
      <>
        {this.state.loading ? <Loading/> : ''}
        <Subheader breadcrumbs={[
          {
            name: locales_es.patientCard,
            href: HREF_PAGE_PATIENTS
          },
          {
            name: this.state.patientId ? locales_es.editCard : locales_es.addCard,
            href: HREF_PAGE_ADD_PATIENT
          }
        ]}/>
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
          <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
            <div className="kt-portlet kt-widget kt-widget--fit kt-widget--general-3">
              {this.auth.getLocalUserType() === USER_TYPE_SECRETARY && this.state.patientId && (
                <div className="kt-portlet__foot kt-portlet__foot--fit">
                  <div className="kt-widget__nav ">
                    <ul
                      className="nav nav-tabs nav-tabs-space-xl nav-tabs-line nav-tabs-clear nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-portlet__space-x"
                      role="tablist">
                      <li className="nav-item">
                        <a
                          className={`nav-link cursor-pointer ${this.state.activeTab === 'editPatient' ? 'active' : ''}`}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({activeTab: 'editPatient'}, () => this.parseTelInputs());
                          }}>
                          <i
                            className="flaticon2-calendar-3"></i> {this.state.patientId ? locales_es.editPatient : locales_es.addPatientCard}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link cursor-pointer ${this.state.activeTab === 'appointments' ? 'active' : ''}`}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({activeTab: 'appointments'}, () => this.getPatientAppointments());
                          }}>
                          <i className="flaticon2-calendar-3"></i> {locales_es.appointments}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>)}
              {this.state.activeTab === 'editPatient' && (
                <Form
                  styles="kt-form"
                  inputs={inputs}
                  handleChange={this.handleChange}
                  handleDateChange={this.handleDateChange}
                  handleReactSelectChange={this.handleReactSelectChange}
                  onSubmit={() => this.send()}
                  onSubmitButtonText={locales_es.send}
                  secondaryButtonText={locales_es.cancel}
                  onClickSecondaryButton={() => {
                    this.props.history.goBack()
                  }}
                  showTerms={false}
                  wrapper={true}
                >
                  {!this.state.patientId && (
                    <div className="kt-portlet__head mt-5">
                      <div className="kt-portlet__head-label">
                        <h3
                          className="kt-portlet__head-title mb-5">{this.state.patientId ? locales_es.editPatient : locales_es.addPatientCard}</h3>
                      </div>
                    </div>
                  )}
                </Form>
              )}
              {this.state.activeTab === 'appointments' && (
                <div className="kt-portlet__body">
                  {appointments === null ? <Spinner /> :
                    appointments && appointments.length ?
                  <AppointmentsList appointments={appointments || []}
                                    timezoneOptions={timezoneOptions}
                                    refresh={() => this.getPatientAppointments()}
                                    showDelete={true}
                                    showVideocall={true}
                                    showAddress={true}
                                    showInfo={true}
                                    showMainModal={this.props.showMainModal}/>
                      :
                    <h6 className="text-center p-3">{locales_es.thisPatientHasNoAppointmentsWithYouYet}</h6>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}
