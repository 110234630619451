import {createContext, useState, useEffect, useContext} from "react";
import AuthService from "../modules/authService";

const initialAuthState = {
    userType: null,
    isLoadingAuth: true,
};

const AuthContext = createContext(initialAuthState);

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within a AddressProvider');
    }
    return context;
};

const AuthProvider = ({children}) => {

    const [isLoadingAuth, setIsLoadingAuth] = useState(true);
    const [userType, setUserType] = useState(null);

    const authService = new AuthService();

    const fetchUserType = () => {
        try {
            const localUserType = authService.getLocalUserType();
            setUserType(localUserType);
        } catch (error) {
            console.error("Error al cargar el tipo de usuario:", error);
            setUserType(null);
        } finally {
            setIsLoadingAuth(false);
        }
    };

    useEffect(() => {
        fetchUserType();
    }, []);


    const contextUserValue = {
        isLoadingAuth,
        userType,
    };

    return (
        <AuthContext.Provider value={contextUserValue}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};
