import React, { useState, useEffect } from 'react';
import './CustomTooltip.css';

const CustomTooltip = ({
    containerStyle = {},
    children,
    text,
    position = 'top',
    extraClass = '',
    extraStyle = {},
    trigger = 'hover', // Puede ser 'hover' o 'click'
    timeout = 0 // Tiempo en ms antes de ocultar automáticamente el tooltip
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        let timer;
        if (showTooltip && timeout > 0) {
            timer = setTimeout(() => {
                setShowTooltip(false);
            }, timeout);
        }
        return () => clearTimeout(timer);
    }, [showTooltip, timeout]);

    // Función para calcular los estilos de posición del tooltip
    const getTooltipStyle = () => {
        let tooltipStyle = {};
        switch (position) {
            case 'top':
                tooltipStyle = {
                    bottom: '125%',
                    left: '50%',
                    marginLeft: '-100px',
                };
                break;
            case 'bottom':
                tooltipStyle = {
                    top: '125%',
                    left: '50%',
                    marginLeft: '-100px',
                };
                break;
            case 'left':
                tooltipStyle = {
                    top: '50%',
                    right: '125%',
                    marginTop: '-10px',
                };
                break;
            case 'right':
                tooltipStyle = {
                    top: '50%',
                    left: '125%',
                    marginTop: '-10px',
                };
                break;
            default:
                tooltipStyle = {
                    bottom: '125%',
                    left: '50%',
                    marginLeft: '-100px',
                };
                break;
        }
        return tooltipStyle;
    };

    const handleMouseEnter = () => {
        if (trigger === 'hover') {
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        if (trigger === 'hover') {
            setShowTooltip(false);
        }
    };

    const handleClick = () => {
        if (trigger === 'click') {
            setShowTooltip((prev) => !prev);
        }
    };

    return (
        <span
            style={containerStyle}
            className="tooltip-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {children}
            <span
                className={`tooltip-text ${position} ${showTooltip ? 'visible' : ''} ${extraClass}`}
                style={{ ...getTooltipStyle(), ...extraStyle }}
            >
                {text}
            </span>
        </span>
    );
};

export default CustomTooltip;
