import {createContext, useContext, useReducer} from 'react';
import OptionsModal from "../components/Modals/OptionModal";
import ModalAnimationResult from "../components/modalAnimationResult";
import StandardModal from "../components/Modals/StandardModal";
import {MODAL_STATE, MODAL_TYPE} from "../models/constants";

const initialModalState = {
    state: {},
    dispatch: () => {}
};

export const ModalContext = createContext(initialModalState);

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModalContext must be used within a AddressProvider');
    }
    return context;
};

const modalReducer = (state, action) => {
    switch (action.type) {
        case MODAL_STATE.OPEN:
            return {
                ...state,
                [action.modalName]: {
                    ...(state[action.modalName] || {}),
                    isOpen: true,
                    type: action.modalType,
                    data: action.payload,
                },
            };
        case MODAL_STATE.CLOSE:
            return {
                ...state,
                [action.modalName]: {isOpen: false, type: null, data: null},
            };
        default:
            return state;
    }
};

export const ModalProvider = ({children}) => {
    const [state, dispatch] = useReducer(modalReducer, {});

    return (
        <ModalContext.Provider value={{state, dispatch}}>
            {children}
            {Object.keys(state).map((modalName) => {
                const modalState = state[modalName];
                if (modalState.isOpen) {
                    switch (modalState.type) {
                        case MODAL_TYPE.OPTION:
                            return <OptionsModal key={modalName} modalName={modalName}/>;
                        case MODAL_TYPE.RESULT:
                            return <ModalAnimationResult
                                key={modalName}
                                modalName={modalName}
                                result={modalState.data.result}
                                titleSuccess={modalState.data.titleSuccess}
                                titleFailed={modalState.data.titleFailed}
                                acceptAction={() => {
                                    if (modalState.data.acceptAction) {
                                        modalState.data.acceptAction()
                                    }
                                    dispatch({type: MODAL_STATE.CLOSE, modalName})
                                }}
                            />;
                        case MODAL_TYPE.STANDARD:
                            return <StandardModal key={modalName} modalName={modalName}/>;
                        default:
                            return null;
                    }
                }
                return null;
            })}
        </ModalContext.Provider>
    );
};
