import {MODAL_STATE} from "../../models/constants";
import locales_es from "../../locales/es";
import {useModalContext} from "../../context/ModalContext";
import Spinner from "../spinner";

const OptionsModal = ({modalName}) => {
    const {state, dispatch} = useModalContext();
    const modalState = state[modalName];

    if (!modalState?.isOpen) {
        return null;
    }

    const handleOptionSelect = (id) => {
        dispatch({type: MODAL_STATE.CLOSE, modalName});
        if (modalState.data?.onSelect) {
            modalState.data.onSelect(id);
        }
    };

    const handleClose = () => {
        dispatch({type: MODAL_STATE.CLOSE, modalName});
        if (modalState.data?.onCancel) {
            modalState.data.onCancel();
        }
    };

    const {title, description, options} = modalState.data || {};

    return (
        <div className="woopi-overlay woopi-price-selection">
            <div className="woopi-overlay-content">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="m-4">{title}</h4>
                    </div>
                </div>
                <div className="row mt-3">
                    {description && <p>{description}</p>}
                </div>
                <div className="row mt-3">
                    <div className="col">
                        {options ? options.length ? (
                            options.map((option, index) => (
                                <div
                                    key={`option-${index}`}
                                    className="row woopi-price-selection--container"
                                    onClick={() => handleOptionSelect(option.id)}>
                                    {option.price !== null && (
                                        <div className="col-4 text-center woopi-price-selection--price">
                                            {option.currency_id || locales_es.currency_sign} {option.price}
                                        </div>
                                    )}
                                    <div className={`col`}>
                                        <p className="font-weight-bold">{option.title}</p>
                                        <p>{option.description}</p>
                                    </div>
                                    <div className={`col-1`}>
                                        <i className="fa fa-chevron-right"/>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>{locales_es.genericErrorContactSiteAdministrator}</div>
                        ) : (
                            <Spinner/>
                        )}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col text-center">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            handleClose();
                        }}>
                            {locales_es.cancel}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OptionsModal;
