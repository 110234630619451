import { createContext, useState, useEffect } from "react";
import APIService from "../modules/apiService";

export const SubscriptionPlanContext = createContext(null);

export const SubscriptionPlanProvider = ({ children }) => {
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const api = new APIService();
        api.getMyPlan()
            .then((res) => {
                if (res.status) {
                    setSubscriptionPlan(res.data);
                } else {
                    setError("Error al obtener el plan de suscripción");
                }
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);

    return (
        <SubscriptionPlanContext.Provider value={{ subscriptionPlan, loading, error }}>
            {children}
        </SubscriptionPlanContext.Provider>
    );
};
