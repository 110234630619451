import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {HREF_PAGE_HOME, USER_TYPE_PATIENT} from "../../models/constants";
import locales_es from "../../locales/es";
import {useAuthContext} from "../../context/AuthContext";
import {useAddressContext} from "../../context/AddressContext";
import AddUserAddressForm from "../../components/UserAddresses/AddUserAddressForm";
import Loading from "../../components/loading";

const AddUserAddressPage = () => {

    const {userType, isLoadingAuth} = useAuthContext();
    const params = useParams();
    const addressId = params.addressId;

    const {
        addressData,
        isLoadingAddress,
        hasLoadedAddressData,
        addressDefaultLocationMarker,
        getAddressById
    } = useAddressContext();

    useEffect(() => {
        if (!isLoadingAuth) {
            if (userType !== USER_TYPE_PATIENT) {
                window.location.href = HREF_PAGE_HOME;
            }
        }
    }, [isLoadingAuth, userType]);

    useEffect(() => {
        if (addressId) {
            getAddressById(addressId);
        }
    }, [addressId]);

    if (isLoadingAuth || isLoadingAddress) {
        return <Loading/>;
    }

    if (addressId && !hasLoadedAddressData) {
        return <Loading/>;
    }

    return (
        <>
            <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app mt-3">
                <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title mb-5">{addressId ? locales_es.userAddresses.pageTitleEdit : locales_es.userAddresses.pageTitleAdd}</h3>
                        </div>
                    </div>
                    <AddUserAddressForm addressId={addressId} formData={addressData} initialDefaultLocationMarker={addressDefaultLocationMarker}/>
                </div>
            </div>
        </>
    );
};

export default AddUserAddressPage;
