import {
    apiURL_login,
    apiURL_register,
    apiURL_users,
    apiURL_user_me,
    apiURL_password_request,
    apiURL_user_password,
    apiURL_medics,
    apiURL_config,
    apiURL_identification_types,
    apiURL_genders,
    apiURL_consulting_rooms,
    apiURL_timetables,
    apiURL_appointments,
    apiURL_appointments_next,
    apiURL_appointments_nexts,
    apiURL_appointments_me,
    apiURL_patients_me,
    apiURL_nonworking_days,
    apiURL_nonworking_days_collisions,
    apiURL_profile_image,
    apiURL_clinics,
    apiURL_posts,
    apiURL_stats_totals,
    apiURL_posts_merged,
    apiURL_specialties,
    apiURL_medics_unassign,
    apiURL_patients,
    apiURL_appointments_block,
    apiURL_timezones,
    apiURL_appointments_types,
    apiURL_medical_insurances_me,
    apiURL_medics_me,
    apiURL_medic_patient,
    apiURL_messages,
    apiURL_appointments_videocall_token,
    apiURL_medic_patient_hash,
    apiURL_medics_appointment_types,
    apiURL_messages_image,
    apiURL_medics_interrupted_agenda,
    apiURL_user_cellphone,
    apiURL_cellphone_request_validation,
    apiURL_cellphone_validation,
    apiURL_appointments_status,
    apiURL_payment_methods,
    apiURL_payment_config,
    apiURL_mercadopago_info,
    apiURL_mercadopago_auth_link,
    apiURL_payment_amount_types,
    apiURL_payment_methods_user,
    apiURL_payment_method_bank_transfer,
    apiURL_payments_status,
    apiURL_appointment_payments_status,
    apiURL_clinics_domain,
    apiURL_clinics_user,
    apiURL_clinics_me,
    apiURL_medics_slugname,
    apiURL_timetables_prices,
    apiURL_medical_info,
    apiURL_medical_record_types,
    apiURL_medical_records,
    apiURL_medics_interrupted_agenda_only_patients,
    apiURL_patients_search,
    apiURL_medics_deactivate,
    apiURL_medical_insurances,
    apiURL_medical_insurances_patients,
    apiURL_professional_registrations_types,
    apiURL_professional_registrations_me,
    apiURL_professional_registrations_jurisdictions,
    apiURL_professional_registrations,
    apiURL_prescriptions_me,
    apiURL_prescription_profiles,
    apiURL_prescription_profiles_me,
    apiURL_prescription_profile_logo_image_base64,
    apiURL_prescriptions,
    apiURL_signature_image_base64,
    apiURL_medicines_search,
    apiURL_prescriptions_patient,
    apiURL_certificates_patient,
    apiURL_certificates_me,
    apiURL_certificates,
    apiURL_posts_services_messages,
    apiURL_currencies,
    apiURL_paypal_credentials_me,
    apiURL_payment_method_bank_transfer_usd,
    apiURL_currencies_me,
    apiURL_guides_articles,
    apiURL_guides_categories,
    apiURL_medic_patient_link,
    apiURL_medic_user_link,
    apiURL_appointments_waiting_room,
    apiURL_complementary_studies,
    apiURL_complementary_studies_types,
    apiURL_persons,
    apiURL_persons_me,
    apiURL_persons_search,
    apiURL_patients_person,
    apiURL_plans,
    apiURL_reports_appointments,
    apiURL_notifications_config,
    apiURL_patients_addresses,
    apiURL_patients_addresses_me,
    apiURL_medical_prescription
}
    from "../models/api-urls";

// eslint-disable-next-line
import unregister from './apiService.config'; // No estamos usando el register(), pero importarlo hace que funcione el interceptor

import AuthService from './authService';
import axios from 'axios';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

class APIService {

    callAuthServiceLogout() {
        const auth = new AuthService();
        auth.logout(true);
    }

    parseError(err) {
        let msg = '';

        msg =
          err && err.data && err.data.message
            ? err.data.message
            : err.message;


        if (
          err &&
          err.errors &&
          err.errors.length
        ) {
            err.errors.map(e => {
                if (e !== msg) {
                    msg += '. ' + e;
                }
            });
        }

        return msg;
    }

    setParams = (urlStr, params) => {
        const url = new URL(urlStr);
        // params = {lat:35.696233, long:139.570431};
        if (params) {
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        }
        return url;
    };

    getConfig = async () => {
        const response = await fetch(apiURL_config, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPostsServicesMessages = async (clinicId) => {
        const response = await fetch(apiURL_posts_services_messages + '?clinic_id=' + clinicId, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postPostsServicesMessages = async (data) => {
        const response = await fetch(apiURL_posts_services_messages, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putPostsServicesMessages = async (postServiceId, data) => {
        const response = await fetch(apiURL_posts_services_messages + '/' + postServiceId, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deletePostsServicesMessages = async (postServiceId) => {
        const response = await fetch(apiURL_posts_services_messages + '/' + postServiceId, {
            method: 'DELETE',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getCellphoneValidation = async () => {
        const response = await fetch(apiURL_cellphone_request_validation, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postCellphoneValidation = async (data) => {
        const response = await fetch(apiURL_cellphone_validation, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getCurrencies = async () => {
        const url = apiURL_currencies;
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyCurrencies = async () => {
        const url = apiURL_currencies_me;
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPaymentMethods = async (userId) => {
        const url = apiURL_payment_methods + (userId ? ('/user/' + userId) : '');
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPaymentsAmountTypes = async () => {
        const response = await fetch(apiURL_payment_amount_types, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putPaymentConfig = async (data) => {
        const response = await fetch(apiURL_payment_config, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putPaymentMethods = async (data) => {
        const response = await fetch(apiURL_payment_methods, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPaymentMethodsByUser = async (userId) => {
        const response = await fetch(apiURL_payment_methods_user + '/' + userId, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };


    getBankTransfer = async (userId) => {
        const url = apiURL_payment_method_bank_transfer + (userId ? ('/' + userId) : '');
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putBankTransferData = async (data) => {
        const response = await fetch(apiURL_payment_method_bank_transfer, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getBankTransferUSD = async (userId) => {
        const url = apiURL_payment_method_bank_transfer_usd + (userId ? ('/' + userId) : '');
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putBankTransferUSDData = async (data) => {
        const response = await fetch(apiURL_payment_method_bank_transfer_usd, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPaypalInfo = async () => {
        const response = await fetch(apiURL_paypal_credentials_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putPaypalData = async (data) => {
        const response = await fetch(apiURL_paypal_credentials_me, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMercadoPagoInfo = async () => {
        const response = await fetch(apiURL_mercadopago_info, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMercadoPagoAuthorizationLink = async (domain) => {
        const response = await fetch(apiURL_mercadopago_auth_link + '?domain=' + domain, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteMercadoPagoAuthorization = async () => {
        const response = await fetch(apiURL_mercadopago_info, {
            method: 'DELETE',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getProfessionalRegistration = async (id) => {
        const url = new URL(apiURL_professional_registrations + '/' + id);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPrescriptionProfile = async (id) => {
        const url = new URL(apiURL_prescription_profiles + '/' + id);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getProfessionalRegistrationsTypes = async () => {
        const response = await fetch(apiURL_professional_registrations_types, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getProfessionalRegistrationJurisdictions = async () => {
        const response = await fetch(apiURL_professional_registrations_jurisdictions, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyProfessionalRegistrations = async () => {
        const response = await fetch(apiURL_professional_registrations_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getMyPrescriptionProfiles = async () => {
        const response = await fetch(apiURL_prescription_profiles_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getPrescriptionTestProfile = async (profileId) => {
        const response = await fetch(apiURL_prescriptions + '/test_profile/' + profileId, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getMyPrescriptionsOrPatientPrescriptions = async (params, pageUrl, patientId) => {
        const defaultUrl = patientId ? `${apiURL_prescriptions_patient}/${patientId}` : apiURL_prescriptions_me;
        let url = new URL(pageUrl || defaultUrl);
        if (params) {
            url = this.setParams(url, params);
        } else {
            url = this.setParams(url, {per_page: 10});
        }

        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getMyCertificatesOrPatientCertificates = async (params, pageUrl, patientId) => {
        const defaultUrl = patientId ? `${apiURL_certificates_patient}/${patientId}` : apiURL_certificates_me;
        let url = new URL(pageUrl || defaultUrl);
        if (params) {
            url = this.setParams(url, params);
        } else {
            url = this.setParams(url, {per_page: 1});
        }

        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postPrescription = async (data) => {
        const response = await fetch(apiURL_prescriptions, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postCertificate = async (data) => {
        const response = await fetch(apiURL_certificates, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postPrescriptionEmail = async (documents = [], data) => {
        const ids = [];
        documents.map(doc => {
            ids.push(doc.prescriptionId);
        });

        const response = await fetch(apiURL_prescriptions + '/send_email/' + ids.join(','), {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    putMedicalPrescriptionPayment = async (id = [], data) => {
        const response = await fetch(apiURL_prescriptions + '/payment/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getMedicalPrescriptionByHash = async (hash) => {
        const response = await fetch(apiURL_prescriptions + '/hash/' + hash, {
            method: 'GET',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postCertificateEmail = async (documents = [], data) => {
        const ids = [];
        documents.map(doc => {
            ids.push(doc.certificateId);
        });

        const response = await fetch(apiURL_certificates + '/send_email/' + ids.join(','), {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postProfessionalRegistration = async (data) => {
        const response = await fetch(apiURL_professional_registrations, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    putProfessionalRegistration = async (id, data) => {
        const response = await fetch(apiURL_professional_registrations + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postPrescriptionProfile = async (data) => {
        const response = await fetch(apiURL_prescription_profiles, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    putPrescriptionProfile = async (id, data) => {
        const response = await fetch(apiURL_prescription_profiles + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    deleteProfessionalRegistration = async (id) => {
        const response = await fetch(apiURL_professional_registrations + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    deleteSignature = async () => {
        const response = await fetch(apiURL_medics + '/signature', {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    deletePrescriptionProfile = async (id) => {
        const response = await fetch(apiURL_prescription_profiles + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getClinics = async () => {
        const response = await fetch(apiURL_clinics, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyClinics = async () => {
        const response = await fetch(apiURL_clinics_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getClinicsByUser = async (id) => {
        const response = await fetch(apiURL_clinics_user + '/' + id, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getClinic = async (domain) => {
        const response = await fetch(apiURL_clinics_domain + '?domain=' + domain, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    getClinicById = async (id) => {
        const response = await fetch(apiURL_clinics + '/' + id, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    postClinic = async (data) => {
        const response = await fetch(apiURL_clinics, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    putClinic = async (id, data) => {
        const response = await fetch(apiURL_clinics + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw body;

        return body;
    };

    deleteClinic = async (id) => {
        const url = new URL(apiURL_clinics);
        const response = await fetch(url + '/' + id, {
            method: 'DELETE',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) throw body;

        return body;
    };

    getTotals = async (params) => {
        let url = new URL(apiURL_stats_totals);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMergedAnnouncements = async (params) => {
        let url = new URL(apiURL_posts_merged);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postAnnouncement = async (data) => {
        const response = await fetch(apiURL_posts, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteAnnouncement = async (id) => {
        const url = new URL(apiURL_posts);
        const response = await fetch(url + '/' + id, {
            method: 'DELETE',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    login = async (email, password, clinicId) => {
        const response = await fetch(apiURL_login, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                email,
                password,
                clinic_id: clinicId,
            })
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(this.parseError(body));

        return body;
    };

    register = async (data) => {
        const response = await fetch(apiURL_register, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.errors && body.errors.length ? body.errors[0] : body.message);

        return body;
    };

    patientWebForm = async (data) => {
        const response = await fetch(apiURL_patients + '/web_form', {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.errors && body.errors.length ? body.errors[0] : body.message);

        return body;
    };

    updateCellphone = async (data) => {
        const response = await fetch(apiURL_user_cellphone, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    updateUser = async (data) => {
        const response = await fetch(apiURL_user_me, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    passwordRequest = async (email) => {
        const response = await fetch(apiURL_password_request, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                email,
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    updatePassword = async (old_password, new_password, new_password_confirmation) => {
        const response = await fetch(apiURL_user_password, {
            method: 'PUT',
            headers,
            body: JSON.stringify({
                old_password,
                new_password,
                new_password_confirmation,
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getUserMe = async () => {
        const response = await fetch(apiURL_user_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }

        if (response.status >= 300) throw body;

        return body;
    };

    getUserById = async (id) => {
        const response = await fetch(apiURL_users + '/' + id, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putUsers = async (id, data) => {
        const response = await fetch(apiURL_users + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getNonWorkingDays = async (params) => {
        let url = new URL(apiURL_nonworking_days);
        if (params) {
			url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postNonWorkingDays = async (params) => {
        let url = new URL(apiURL_nonworking_days);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'POST',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteNonWorkingDays = async (id) => {
        const url = new URL(apiURL_nonworking_days);
        const response = await fetch(url + '/' + id, {
            method: 'DELETE',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getNonWorkingDaysCollisions = async (params) => {
        let url = new URL(apiURL_nonworking_days_collisions);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedics = async (params) => {
        let url = new URL(apiURL_medics);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPatientAppointments = async (patient_id, medic_id) => {
        let url = new URL(apiURL_appointments + '/patient/' + patient_id + '/medic/' + medic_id);
            url = this.setParams(url, {patient_id});

        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPersonAppointments = async (id) => {
        let url = new URL(apiURL_appointments + '/person/' + id);

        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalInsuranceByPatient = async (patient_id) => {
        let url = new URL(apiURL_medical_insurances_patients);
            url = this.setParams(url, {patient_id});

        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalStudiesByPatient = async (patient_id, pageUrl) => {
        let url = new URL(apiURL_complementary_studies);
        url = this.setParams(url, {patient_id});
        const response = await fetch(pageUrl || url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalInsuranceByInsuranceId = async (id) => {
        const response = await fetch(apiURL_medical_insurances_patients + '/' + id, {
            method: 'GET',
            headers,
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalInsurances = async () => {
        const response = await fetch(apiURL_medical_insurances, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putMedicalInsurancePatient = async (id, data) => {
        const response = await fetch(apiURL_medical_insurances_patients + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postMedicalInsurancesPatients = async (data) => {
        const response = await fetch(apiURL_medical_insurances_patients, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postMyMedicalInsurancesPatients = async (data) => {
        const response = await fetch(apiURL_medical_insurances_me, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteMedicalInsurancePatient = async (id) => {
        const response = await fetch(apiURL_medical_insurances_patients + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyMedicalInsurances = async () => {
        const response = await fetch(apiURL_medical_insurances_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyMedics = async () => {
        const response = await fetch(apiURL_medics_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicPatientBounding = async (params) => {
        let url = new URL(apiURL_medic_patient);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicPatientBoundingByHash = async (hash) => {
        const response = await fetch(apiURL_medic_patient_hash + '/' + hash, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPaymentMethodsByAppointmentId = async (appointmentId) => {
        const response = await fetch(apiURL_payment_methods + '/appointment/' + appointmentId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicById = async (medicId) => {
        const response = await fetch(apiURL_medics + '/' + medicId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicBySlugname = async (slugname) => {
        const response = await fetch(apiURL_medics_slugname + '/' + slugname, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getVideocallData = async (hash) => {
        const response = await fetch(apiURL_appointments_videocall_token + '/' + hash, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status === 401) {
            throw ('401');
            // return;
        }
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMessages = async (medicPatientId) => {
        const response = await fetch(apiURL_messages + '/' + medicPatientId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postMessage = async (msg, medic_patient_id, clinic_id) => {
        const response = await fetch(apiURL_messages, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                msg,
                medic_patient_id,
                clinic_id,
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postMedic = async (data) => {
        const response = await fetch(apiURL_medics, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putMedicClinicSort = async (data) => {
        const response = await fetch(apiURL_medics + '/clinic/sort', {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putMedic = async (data) => {
        const response = await fetch(apiURL_medics, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putInterruptedAgenda = async (userId, data) => {
        const response = await fetch(apiURL_medics_interrupted_agenda + '/' + userId, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putInterruptedAgendaOnlyPatients = async (userId, data) => {
        const response = await fetch(apiURL_medics_interrupted_agenda_only_patients + '/' + userId, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postLinkPatientToMedic = async (data) => {
        const response = await fetch(apiURL_medic_patient_link, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postLinkUserToMedic = async (data) => {
        const response = await fetch(apiURL_medic_user_link, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postPatient = async (data) => {
        const response = await fetch(apiURL_patients, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putPatient = async (id, data) => {
        const response = await fetch(apiURL_patients + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deletePatient = async (id) => {
        const response = await fetch(apiURL_patients + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalRecordTypes = async () => {
        const response = await fetch(apiURL_medical_record_types, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalRecordsViewersByPatient = async (id) => {
        const url = apiURL_medical_records + '/patient/' + id + '/viewers'
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalRecordsByPatient = async (id, pageUrl) => {
        // const url = apiURL_medical_records + '/patient/' + id + '?per_page=3';
        const url = apiURL_medical_records + '/patient/' + id;
        const response = await fetch(pageUrl || url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postMedicalStudy = async (file, data) => {
        const auth = new AuthService();

        const headers = {
            'AuthorizationJWT': `Bearer ${auth.getAccessToken()}`,
        };

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object with the file
        formData.append("file", file, file.name);

        // Add other fields from data to formData
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key) && key !== "file") {
                formData.append(key, data[key]);
            }
        }

        console.log(formData);

        return axios.post(apiURL_complementary_studies, formData, {
            headers: headers
        });
    };

    postMedicalRecord = async (data) => {
        const response = await fetch(apiURL_medical_records, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putMedicalRecord = async (id, data) => {
        const response = await fetch(apiURL_medical_records + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalRecord = async (id) => {
        const response = await fetch(apiURL_medical_records + '/' + id, {
            method: 'GET',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteMedicalRecord = async (id) => {
        const response = await fetch(apiURL_medical_records + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalRecordExport = async (patientId, params) => {
        let url = new URL(apiURL_medical_records + '/patient/export/' + patientId);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteMedicalStudy = async (id) => {
        const response = await fetch(apiURL_complementary_studies + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicalInfo = async (id) => {
        const response = await fetch(apiURL_medical_info + '/' + id, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putMedicalInfo = async (data) => {
        const response = await fetch(apiURL_medical_info, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getIdentificationTypes = async () => {
        const response = await fetch(apiURL_identification_types, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getComplementaryStudiesTypes = async () => {
        const response = await fetch(apiURL_complementary_studies_types, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getGenders = async () => {
        const response = await fetch(apiURL_genders, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getTimezones = async () => {
        const response = await fetch(apiURL_timezones, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getSpecialties = async (params) => {
        let url = new URL(apiURL_specialties);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getConsultingRooms = async (params) => {
        let url = new URL(apiURL_consulting_rooms);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getAppointmentsTypes = async (params) => {
        let url = new URL(apiURL_appointments_types);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMedicAppointmentsTypes = async (medicId) => {
        const response = await fetch(apiURL_medics_appointment_types + '/' + medicId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getTimetables = async (params) => {
        let url = new URL(apiURL_timetables);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getTimetableById = async (id) => {
        let url = new URL(apiURL_timetables + '/' + id);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postTimetables = async (data) => {
        const response = await fetch(apiURL_timetables, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(this.parseError(body));

        return body;
    };

    putTimetable = async (data) => {
        const response = await fetch(apiURL_timetables, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteTimetables = async (id) => {
        const response = await fetch(apiURL_timetables + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getTimetablePrices = async (id) => {
        const response = await fetch(apiURL_timetables + '/' + id + "/prices", {
            method: 'GET',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(this.parseError(body));

        return body;
    };

    postTimetablePrice = async (data) => {
        const response = await fetch(apiURL_timetables_prices, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(this.parseError(body));

        return body;
    };

    putTimetablePrice = async (id, data) => {
        const response = await fetch(apiURL_timetables_prices + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteTimetablePrice = async (id) => {
        const response = await fetch(apiURL_timetables_prices + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    unassignMedic = async (params) => {
        let url = new URL(apiURL_medics_unassign);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deactivateMedic = async (id, data) => {
        const response = await fetch(apiURL_medics_deactivate + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getNextAppointment = async (params, isPublic) => {
        let url = new URL(apiURL_appointments_next);
        url += isPublic ? '/public' : '/private';
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getNextAppointments = async (params, isPublic) => {
        let url = new URL(apiURL_appointments_nexts);
        url += isPublic ? '/public' : '/private';
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPaymentsStatus = async () => {
        const response = await fetch(apiURL_payments_status, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putPaymentsStatus = async (id, data) => {
        const response = await fetch(apiURL_appointment_payments_status + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getAppointmentsStatus = async () => {
        const response = await fetch(apiURL_appointments_status, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getAppointments = async (params, isPublic) => {
        let url = new URL(apiURL_appointments);
        url += isPublic ? '/public' : '/private';
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getAppointment = async (id) => {
        const response = await fetch(apiURL_appointments + '/' + id, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyAppointments = async (params) => {
        let url = new URL(apiURL_appointments_me);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getAppointmentsReports = async (params) => {
        let url = new URL(apiURL_reports_appointments);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPatients = async (params, pageUrl) => {
        let url = new URL(pageUrl || apiURL_patients);
        if (params) {
            url = this.setParams(url, params);
        } else {
            url = this.setParams(url, {per_page: 100});
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPatient = async (userId) => {
        const url = new URL(apiURL_patients + '/id/' + userId);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyPatients = async (params, pageUrl) => {
        let url = new URL(pageUrl || apiURL_patients_me);
        if (params) {
            url = this.setParams(url, params);
        } else {
            url = this.setParams(url, {per_page: 100});
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    searchPatients = async params => {
        let url = new URL(apiURL_patients_search);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPersons = async (params, pageUrl) => {
        let url = new URL(pageUrl || apiURL_persons);
        if (params) {
            url = this.setParams(url, params);
        } else {
            url = this.setParams(url, {per_page: 100});
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPerson = async (id) => {
        const url = new URL(apiURL_persons + '/id/' + id);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyPersons = async (params, pageUrl) => {
        let url = new URL(pageUrl || apiURL_persons_me);
        if (params) {
            url = this.setParams(url, params);
        } else {
            url = this.setParams(url, {per_page: 100});
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    searchPersons = async params => {
        let url = new URL(apiURL_persons_search);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPatientsByPersonAndClinic = async (person_id, clinic_id) => {
        const url = new URL(apiURL_patients_person + '/' + person_id + '/clinic/' + clinic_id);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    }

    getPatientsByMedicAndPerson = async (medic_id, person_id) => {
        const url = new URL(apiURL_patients + '/medic/' + medic_id + '/person/' + person_id);
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    }

    searchMedicines = async params => {
        let url = new URL(apiURL_medicines_search);
        if (params) {
            url = this.setParams(url, params);
        }
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postAppointment = async (data) => {
        const response = await fetch(apiURL_appointments, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;

    };

    postAppointmentWaitingRoom = async (data) => {
        const response = await fetch(apiURL_appointments_waiting_room, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;

    };

    getAppointmentWaitingRoom = async (params) => {
        const { consulting_rooms_ids, specialty_ids, ...otherParams } = params;

        // Crear un objeto params que se pasará a setParams, incluyendo otros parámetros
        const queryParams = Object.assign({}, otherParams);
        if (consulting_rooms_ids && consulting_rooms_ids.length) {
            queryParams.consulting_rooms_ids = consulting_rooms_ids.join(',');
        }
        if (specialty_ids && specialty_ids.length) {
            queryParams.specialty_ids = specialty_ids.join(',');
        }

        // Construir la URL con los parámetros
        const url = this.setParams(apiURL_appointments_waiting_room, queryParams);

        // Realizar la solicitud fetch
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putAppointment = async (id, data) => {
        const response = await fetch(apiURL_appointments + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putAppointmentStatus = async (id, data) => {
        const response = await fetch(apiURL_appointments_status + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };


    postBlockAppointment = async (data) => {
        const response = await fetch(apiURL_appointments_block, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;

    };

    cancelAppointment = async (id) => {
        const response = await fetch(apiURL_appointments + '/' + id, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;

    };

    uploadProfileImage = async (file) => {

        const auth = new AuthService();

        const headers = {
            'AuthorizationJWT': `Bearer ${auth.getAccessToken()}`,
        };

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "profile_image",
            file,
            file.name
        );

        // Details of the uploaded file
        console.log(file);

        // Request made to the backend api
        // Send formData object
        return axios.post(apiURL_profile_image, formData, {
            headers: headers
        });
    };

    uploadMedicProfileImage = async (base64, userId) => {
        const response = await fetch(apiURL_users + '/' + userId + '/profile_image_base64', {
            method: 'POST',
            headers,
            body: JSON.stringify({
                profile_image: base64,
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postSignatureImageBase64 = async (base64) => {
        const response = await fetch(apiURL_signature_image_base64, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                signature: base64,
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postLogoImageBase64 = async (prescriptionProfileId, base64) => {
        const response = await fetch(apiURL_prescription_profile_logo_image_base64 + '/' + prescriptionProfileId, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                logo_image: base64,
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    deleteLogoImage = async (prescriptionProfileId) => {
        const response = await fetch(apiURL_prescription_profiles + '/logo_image/' + prescriptionProfileId, {
            method: 'DELETE',
            headers,
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    sendImageMessage = async (file, medicPatientId) => {

        const auth = new AuthService();

        const headers = {
            'AuthorizationJWT': `Bearer ${auth.getAccessToken()}`,
        };

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "image",
            file,
            file.name,
        );

        // Update the formData object
        formData.append(
            "medic_patient_id",
            medicPatientId
        );

        // Details of the uploaded file
        console.log(file);

        // Request made to the backend api
        // Send formData object
        return axios.post(apiURL_messages_image, formData, {
            headers: headers
        });
    };

    getGuideCategories = async () => {
        const response = await fetch(apiURL_guides_categories, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getGuideCategory = async (guideCategoryId) => {
        const response = await fetch(apiURL_guides_categories + '/' + guideCategoryId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getArticlesByGuideCategory = async (guideCategoryId) => {
        const response = await fetch(apiURL_guides_articles + '?guide_category_id=' + guideCategoryId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getPlans = async () => {
        const response = await fetch(apiURL_plans, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyPlan = async () => {
        const response = await fetch(apiURL_plans + '/me', {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    postPlanSubscribe = async (planTag) => {
        const response = await fetch(apiURL_plans + '/subscribe', {
            method: 'POST',
            headers,
            body: JSON.stringify({
                plan_tag: planTag
            })
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    getMyAddresses = async () => {
        const response = await fetch(apiURL_patients_addresses_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) throw body;
        return body;
    };

    getAddressesById = async (id) => {
        const response = await fetch(apiURL_patients_addresses + '/' + id, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) throw body;
        return body;
    };

    postAddress = async (data) => {
        const response = await fetch(apiURL_patients_addresses, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();
        if (response.status >= 300) throw body;
        return body;
    };

    putAddress = async (id, data) => {
        const response = await fetch(apiURL_patients_addresses + '/' + id, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();
        if (response.status >= 300) throw body;
        return body;
    };

    deleteAddress = async (id) => {
        const url = new URL(apiURL_patients_addresses);
        const response = await fetch(url + '/' + id, {
            method: 'DELETE',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) throw body;
        return body;
    };

    postMedicalPrescription = async (data) => {
        const response = await fetch(apiURL_medical_prescription, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();
        if (response.status >= 300) throw body;
        return body;
    };

    getNotificationsSettings = async (userId) => {
        const response = await fetch(apiURL_notifications_config + '/' + userId, {
            method: 'GET',
            headers
        });
        const body = await response.json();
        if (response.status >= 300) {
            throw body;
        }

        return body;
    };

    putNotificationsSettings = async (userId, data) => {
        const response = await fetch(apiURL_notifications_config + '/' + userId, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) {
            throw body;
        }

        return body;
    };
}

//unregister();

export default APIService;
