import React, {useEffect, useState} from "react";
import locales_es from "../../locales/es";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../spinner";

const NotificationsSettings = ({userData, showMainModal}) => {
  const api = new APIService();
  const helpers = new Helpers();

  const [loading, setLoading] = useState(false);

  const [notificationSettings, setNotificationSettings] = useState({
    enable_appointment_email_notifications: null,
    enable_appointment_cancel_email_notifications: null,
    enable_webform_email_notifications: null,
  });

  // Cargar las configuraciones iniciales
  useEffect(() => {
    if (userData) {
      api
        .getNotificationsSettings(userData.id)
        .then((response) => {
          setNotificationSettings(response.data);
        })
        .catch((error) => {
          showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(error));
        });
    }
  }, [userData]);

  // Manejar el cambio de los checkboxes
  const handleChange = (field) => {
    const updatedSettings = {
      ...notificationSettings,
      [field]: !notificationSettings[field],
    };

    setNotificationSettings(updatedSettings);
    setLoading(true);
    api
      .putNotificationsSettings(userData.id, updatedSettings)
      .catch((error) => {
        showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(error));
      }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {loading && (
        <div className="row mt-3">
          <div className="col text-center">
            <Spinner/>
          </div>
        </div>
      )}
      <div className="row mt-3">
        <div className="col">
          {notificationSettings.enable_appointment_email_notifications !== null && (
            <label className="kt-checkbox">

              <input
                type="checkbox"
                onChange={() => handleChange("enable_appointment_email_notifications")}
                checked={notificationSettings.enable_appointment_email_notifications}
              />
              <span/> {locales_es.enableAppointmentConfirmationEmailNotifications}
            </label>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          {notificationSettings.enable_appointment_cancel_email_notifications !== null && (
            <label className="kt-checkbox">
              <input
                type="checkbox"
                onChange={() => handleChange("enable_appointment_cancel_email_notifications")}
                checked={notificationSettings.enable_appointment_cancel_email_notifications}
              />
              <span/> {locales_es.enableAppointmentCancellationEmailNotifications}
            </label>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          {notificationSettings.enable_webform_email_notifications !== null && (
            <label className="kt-checkbox">
              <input
                type="checkbox"
                onChange={() => handleChange("enable_webform_email_notifications")}
                checked={notificationSettings.enable_webform_email_notifications}
              />
              <span/> {locales_es.enableWebformEmailNotifications}
            </label>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationsSettings;
