import React, {Component} from 'react';
import {RWebShare} from 'react-web-share';
import './styles.css';
import SendEmailPrescriptions from "../../components/SendEmailPrescriptions";
import locales_es from '../../locales/es';
import {MEDICAL_PRESCRIPTION_TYPE, USER_TYPE_MEDIC, USER_TYPE_PATIENT} from "../../models/constants";
import ChargePrescriptions from "../../components/ChargePrescriptions";
import AuthService from "../../modules/authService";

class DocumentVisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailOverlay: false,
      showPaymentOverlay: false,
      hasUser: this.props.location.state.emails?.length > 0,
    }
    this.auth = new AuthService();
  }

  setShowEmailOverlay(bool) {
    this.setState({
      showEmailOverlay: bool
    })
  }

  setShowPaymentOverlay(bool) {
    this.setState({
      showPaymentOverlay: bool
    })
  }

  render() {
    const {documents, emails, cellphone, prescriptionType} = this.props.location.state;
    const {showEmailOverlay, showPaymentOverlay} = this.state;

    return (
      <div>
        {this.auth.getLocalUserType() !== USER_TYPE_PATIENT && this.state.hasUser ? (
          <div className="alert alert-dark" role="alert">
            <div className="alert-icon"><i className="flaticon-user-ok"></i></div>
            <div className="alert-text"
                 dangerouslySetInnerHTML={{__html: locales_es.thisPatientHasAUserAccountAndIsAbleToSeeThisDocument}}/>
            <div className="alert-close">
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i className="la la-close"></i></span>
              </button>
            </div>
          </div>
        ) : null}
        {showEmailOverlay && (
          <SendEmailPrescriptions
            prescriptionType={prescriptionType}
            documents={documents}
            emails={emails}
            showMainModal={this.props.showMainModal}
            onClose={() => this.setShowEmailOverlay(false)}
          />
        )}
        {showPaymentOverlay && (
          <ChargePrescriptions
            prescriptionType={prescriptionType}
            documents={documents}
            emails={emails}
            cellphone={cellphone}
            showMainModal={this.props.showMainModal}
            onClose={() => this.setShowPaymentOverlay(false)}
          />
        )}
        {documents.map((doc, index) => (
          <div key={index}>
            <iframe className="woopi-document-visor-iframe" src={doc.pdfSource.uri} title={`Document ${index}`}
                    width="100%" height="600px"/>
          </div>
        ))}
        {this.auth.getLocalUserType() !== USER_TYPE_PATIENT ?
          <>
            {this.auth.getLocalUserType() === USER_TYPE_MEDIC && prescriptionType === MEDICAL_PRESCRIPTION_TYPE && documents.length === 1 ?
              <div className="text-center mt-3" onClick={() => this.setShowPaymentOverlay(true)}>
                <button type="button" className="btn btn-brand btn-elevate btn-pill btn-elevate-air"><i
                  className="la la-money"/> Cobrar esta prescripción
                </button>
              </div>
              : null}
            <div className="text-center mt-3" onClick={() => this.setShowEmailOverlay(true)}>
              <button type="button" className="btn btn-success btn-elevate btn-pill btn-elevate-air"><i
                className="la la-inbox"/> Enviar por E-mail
              </button>
            </div>
            <div className="text-center mt-3">
              <a
                href={`https://wa.me/${cellphone ? cellphone.replace(/\s+/g, '') : ''}?text=Aquí están sus prescripciones ${documents.map(doc => doc.pdfSource.uri).join(', ')}`}
                target="_blank" className="btn btn-outline-brand btn-elevate btn-pill"><i
                className="la la-whatsapp"></i>{locales_es.whatsappShareButtonText}</a>
            </div>
            <div className="text-center mt-3">
              <RWebShare
                closeText={locales_es.close}
                data={{
                  text: 'Aquí están sus prescripciones',
                  url: documents.map(doc => doc.pdfSource.uri).join(', '),
                  title: 'Documentos Compartidos de MediCloud',
                }}
                onClick={() => console.log('Compartido exitosamente')}
              >
                <button className="btn btn-outline-accent btn-elevate btn-pill"><i
                  className="la la-paper-plane"></i>{locales_es.rnShareButtonText}</button>
              </RWebShare>
            </div>
          </>
          : null}
      </div>
    );
  }
}

export default DocumentVisor;
