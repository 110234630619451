import locales_es from "../../locales/es";
import AddUserAddressButton from "../../components/UserAddresses/AddUserAddressButton";
import Title from "./Title";

const EmptyAddressList = () => {
    return (
        <>
            <Title title={locales_es.userAddresses.pageTitle}/>
            <div className="kt-portlet p-3">
                <div className="alert alert-warning fade show mt-3" role="alert">
                    <div className="alert-icon">
                        <i className="flaticon-warning"></i>
                    </div>
                    <div className="alert-text">{locales_es.userAddresses.emptyListMessage}.</div>
                </div>
                <AddUserAddressButton/>
            </div>
        </>
    );
};

export default EmptyAddressList;
