import { createContext, useState, useEffect } from "react";
import APIService from "../modules/apiService";

export const AppointmentTypesContext = createContext(null);

export const AppointmentTypesProvider = ({ children }) => {
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const api = new APIService();
        api.getAppointmentsTypes()
            .then((res) => {
                if (res.status) {
                    setAppointmentTypes(res.data);
                } else {
                    setError("Error al obtener los tipos de turnos");
                }
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, []);

    return (
        <AppointmentTypesContext.Provider value={{ appointmentTypes, loading, error }}>
            {children}
        </AppointmentTypesContext.Provider>
    );
};
