import React from 'react';

export default function Button({ children, onClick, className }) {
    return (
        <button
            type="button"
            className={className || "btn btn-focus btn-elevate btn-pill"}
            onClick={onClick}
        >
            <i className="flaticon2-calendar-5"></i> {children}
        </button>
    );
}
