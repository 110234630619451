import {useEffect, useRef} from "react";

const IframeWithHtmlContent = ({htmlContent}) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const doc = iframe.contentDocument || iframe.contentWindow.document;
            doc.open();
            doc.write(htmlContent);
            doc.close();
        }
    }, [htmlContent]);

    return (
        <iframe
            ref={iframeRef}
            className="w-100 h-100 border-0"
            width="100%"
            height="100%"
            allowFullScreen
            border="0"
        />
    );
};

export default IframeWithHtmlContent;
