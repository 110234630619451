import {useEffect, useRef} from 'react';
import {useModalContext} from "../../context/ModalContext";
import {MODAL_STATE, STANDARD_MODAL_TYPE} from "../../models/constants";


const StandardModal = ({modalName}) => {

    const {state, dispatch} = useModalContext();

    const modalState = state[modalName];
    const modalRef = useRef(null);

    const modalId = modalState?.data.modalId;
    const title = modalState?.data.title;
    const width = modalState?.data.width;
    const height = modalState?.data.height;
    const heightBody = modalState?.data.heightBody;
    const visible = modalState?.isOpen;
    const actions = modalState?.data.actions;
    const hideCloseButton = modalState?.data.hideCloseButton;
    const children = modalState?.data.children;

    const widthClass = width ? `modal-${width}` : '';
    const heightClass = {
        minHeight: height ? `${height}` : '',
        overflowY: 'auto'
    };

    useEffect(() => {
        if (visible) {
            window.$(`#${modalId}`).modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            });
        } else {
            window.$(`#${modalId}`).modal('hide');
        }

        return () => {
            // Limpieza al desmontar
            window.$(`#${modalId}`).modal('dispose');
        };
    }, [visible, modalId]);

    const handleClose = () => {
        dispatch({type: MODAL_STATE.CLOSE, modalName});
    };

    return (
        <>
            <div ref={modalRef} id={modalId} className={`modal hide`} tabIndex="-1" role="dialog">
                <div className={`modal-dialog modal-dialog-centered ${widthClass}`} role="document">
                    <div style={heightClass} className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            {!hideCloseButton && (
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            )}
                        </div>
                        <div className="modal-body" style={{height: heightBody}}>
                            {children}
                        </div>
                        {actions && actions.length > 0 && (
                            <div className="modal-footer">
                                {actions.map((action, index) => (
                                    <button
                                        key={`modal-action-${index}`}
                                        type="button"
                                        className={action.className}
                                        data-dismiss="modal"
                                        onClick={() => {
                                            if (action.type === STANDARD_MODAL_TYPE.CANCEL) {
                                                if (action.onClick) {
                                                    action.onClick();
                                                }
                                                if (action.closeOnClick) {
                                                    handleClose();
                                                }
                                            } else {
                                                action.onClick();
                                                if (action.closeOnClick) {
                                                    handleClose();
                                                }
                                            }
                                        }}
                                    >
                                        {action.title}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StandardModal;
