import React, {Component} from 'react';
import locales_es from "./../../locales/es";
import {
  BONUS_PLAN_EARLY_ADOPTER,
  DEFAULT_SLUGNAME, EVENT_SHOW_PATIENT_SHARE_LINK, EVENT_SHOW_SHARE_LINK,
  HREF_PAGE_CHANGE_PASSWORD, HREF_PAGE_MEDIC, HREF_PAGE_MY_MEDIC_PROFILE,
  HREF_PAGE_MY_PROFILE, HREF_PAGE_PLANS, HREF_PAGE_TEAM,
  USER_TYPE_MEDIC,
  USER_TYPE_PATIENT
} from "../../models/constants";
import AvatarImage from "../avatarImage";
import AuthService from "../../modules/authService";
import Modal from "../modal";
import "./index.css";
import PlanButton from "../PlanButton";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

export default class UserProfile extends Component {

  constructor(props) {
    super(props);
    this.auth = new AuthService();

    this.state = {
      profileLink: null,
      isBonusPlan: false,
      modal: false,
      modalPatientShare: false,
    };

    this.shareLink = this.shareLink.bind(this);
    this.sharePatientLink = this.sharePatientLink.bind(this);
  }

  componentWillMount() {
    this.load();
  }

  componentDidMount() {
    document.addEventListener(EVENT_SHOW_SHARE_LINK, this.shareLink);
    document.addEventListener(EVENT_SHOW_PATIENT_SHARE_LINK, this.sharePatientLink);
  }

  componentWillUnmount() {
    document.addEventListener(EVENT_SHOW_SHARE_LINK, this.shareLink);
    document.addEventListener(EVENT_SHOW_PATIENT_SHARE_LINK, this.sharePatientLink);
  }

  load() {
    this.auth.getRemoteUserData().then(res => {
      if (res.data && res.data.user) {
        const userData = res.data.user;
        const profileLink = `${window.location.origin}${userData.slugname && userData.slugname !== DEFAULT_SLUGNAME ? ('/' + userData.slugname) : (HREF_PAGE_MEDIC + '/' + userData.id)}`;
        const profilePatientLink = `${window.location.origin}${HREF_PAGE_MEDIC}${userData.slugname && userData.slugname !== DEFAULT_SLUGNAME ? ('/' + userData.slugname) : ('/' + userData.id)}/${locales_es.newPatientURL}`;
        this.setState({
          profileLink,
          profilePatientLink,
        });
      }
    });
  }

  shareLink(data) {
    if (data && data.detail?.medicId) {
      this.setState({
        profileLink: `${window.location.origin}${HREF_PAGE_MEDIC + '/' + data.detail?.medicId}`
      }, () => {
        this.showShareLink();
      });
    } else if (data && data.detail?.clinicId) {
      this.setState({
        profileLink: `${window.location.origin}`
      }, () => {
        this.showShareLink();
      });
    } else {
      this.showShareLink();
    }
  }

  showShareLink() {
    this.setState({
      modal: true,
    }, () => {
      window.initPopovers();
      this.generateQRCode(this.state.profileLink);
    })
  }

  setIsBonusPlan(tag) {
    this.setState({
      isBonusPlan: tag
    });
  }

  sharePatientLink(data) {
    if (data && data.detail?.medicId) {
      this.setState({
        profilePatientLink: `${window.location.origin}${HREF_PAGE_MEDIC}/${data.detail.medicId}/${locales_es.newPatientURL}`
      }, () => {
        this.showSharePatientLink();
      });
    } else if (data && data.detail?.clinicId) {
      this.setState({
        profilePatientLink: `${window.location.origin}${HREF_PAGE_TEAM}/${data.detail.clinicId}/${locales_es.newPatientURL}`
      }, () => {
        this.showSharePatientLink();
      });
    } else {
      this.showSharePatientLink();
      this.generateQRCode();
    }
  }

  showSharePatientLink() {
    this.setState({
      modalPatientShare: true,
    }, () => {
      window.initPopovers();
      this.generateQRCode(this.state.profilePatientLink);
    });
  }

  generateQRCode(code) {
    setTimeout(() => {
      const qrContainer = document.getElementById("qrCodeContainer");
      if (qrContainer && code) {
        qrContainer.innerHTML = "";
        new window.QRCode(qrContainer, {
          text: code,
          width: 128,
          height: 128
        });
      }
    }, 200); // Pequeño delay para asegurar que el modal está montado
  }

  copyQRToClipboard() {
    const qrCanvas = document.querySelector("#qrCodeContainer canvas");
    if (qrCanvas) {
      qrCanvas.toBlob(blob => {
        const item = new ClipboardItem({"image/png": blob});
        navigator.clipboard.write([item]).then(() => {
          alert("QR copiado al portapapeles");
        }).catch(err => {
          console.error("Error al copiar QR:", err);
        });
      });
    }
  }

  downloadQRCode = () => {
    const qrCanvas = document.querySelector("#qrCodeContainer canvas");
    if (qrCanvas) {
      const link = document.createElement("a");
      link.href = qrCanvas.toDataURL("image/png"); // Convertir el canvas a una imagen PNG
      link.download = "qr_code.png"; // Nombre del archivo a descargar
      link.click();
    }
  };

  render() {
    const {userData} = this.props;
    const {modal, profileLink, profilePatientLink, modalPatientShare} = this.state;
    return (
      <>
        <div id="kt_offcanvas_toolbar_profile" className="kt-offcanvas-panel" ref={elem => this.nav = elem}>
          <div className="kt-offcanvas-panel__head">
            <h3 className="kt-offcanvas-panel__title">
              {locales_es.myProfile}
            </h3>
            <a href="#" className="kt-offcanvas-panel__close" id="kt_offcanvas_toolbar_profile_close">
              <i className="flaticon2-delete"/></a>
          </div>
          <div className="kt-offcanvas-panel__body kt-scroll ps ps--active-y">
            <div className="kt-user-card-v3 kt-margin-b-30">
              <div
                className={`kt-user-card-v3__avatar ${this.state.isBonusPlan === BONUS_PLAN_EARLY_ADOPTER ? 'badgeEarlyAdopted' : ''}`}>
                <AvatarImage profileImage={userData.full_profile_image} enableUpload={true}/>
              </div>
              <div className="kt-user-card-v3__detalis">
                <a className="kt-user-card-v3__name">
                  {userData.name} {userData.lastname}
                </a>
                <div className="kt-user-card-v3__desc">
                  {locales_es.user_type[userData.user_type]}
                </div>
                <div className="kt-user-card-v3__info">
                  <a className="kt-user-card-v3__item">
                    <span className="kt-user-card-v3__tag pl-0">{userData.email}</span>
                  </a>
                  {userData.user_type !== USER_TYPE_PATIENT &&
                    <PlanButton onIsBonusPlan={(tag) => this.setIsBonusPlan(tag)}/>}
                </div>
              </div>
            </div>
            <div className="kt-margin-t-40">
              {userData.user_type === USER_TYPE_PATIENT &&
                <a href={HREF_PAGE_MY_PROFILE} type="button"
                   className="btn btn-info btn-font-sm btn-upper btn-bold m-1">
                  {locales_es.editProfile}
                </a>
              }
              {userData.user_type === USER_TYPE_MEDIC &&
                <>
                  <div>
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      window.location.href = HREF_PAGE_PLANS;
                    }} type="button" className="btn btn-brand m-1"><i
                      className="fa fa-crown"></i>Ver Planes</a>
                  </div>
                  <div>
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      this.shareLink();
                    }} type="button" className="btn btn-secondary btn-upper btn-bold m-1"><i
                      className="fa fa-share-alt"></i> Mi perfil médico</a>
                  </div>
                  <div>
                    <a href={HREF_PAGE_MY_MEDIC_PROFILE} type="button"
                       className="btn btn-info btn-font-sm btn-upper btn-bold m-1">
                      {locales_es.editProfile}
                    </a>
                  </div>
                </>
              }
              <a href={HREF_PAGE_CHANGE_PASSWORD} type="button"
                 className="btn btn-warning btn-font-sm btn-upper btn-bold m-1">
                {locales_es.changePassword}
              </a>
              <button onClick={() => this.props.showLogoutModal()} type="button"
                      className="btn btn-danger btn-font-sm btn-upper btn-bold m-1">
                {locales_es.logout}
              </button>
            </div>
          </div>
        </div>
        {modal &&
          <Modal modalId="shareLink"
                 hideCloseButton={true}
                 title={locales_es.shareLinkTitle}
                 visible={modal}
                 actions={this.auth.getLocalUserType() === USER_TYPE_MEDIC ? [
                   {
                     className: 'btn btn-outline-brand btn-elevate btn-pill m-3 align-self-start',
                     title: locales_es.seeMyPublicProfile,
                     onClick: () => {
                       window.open(profileLink, '_blank');
                       this.setState({
                         modal: false
                       })
                     }
                   },
                   {
                     className: 'btn btn-brand btn-elevate btn-pill m-3 align-self-start',
                     title: locales_es.accept,
                     onClick: () => {
                       this.setState({
                         modal: false
                       })
                     }
                   }
                 ] : [
                   {
                     className: 'btn btn-brand btn-elevate btn-pill m-3 align-self-start',
                     title: locales_es.close,
                     onClick: () => {
                       this.setState({
                         modal: false
                       })
                     }
                   }
                 ]}
          >
            <p>{locales_es.shareLinkDescription}</p>
            <div className="text-center pb-3">
              <div id="qrCodeContainer" className="d-inline-block"></div>
            </div>
            <div className="text-center pb-3">
              <button
                className="btn btn-pill btn-outline-info m-1"
                onClick={() => this.copyQRToClipboard()}
              >
                {locales_es.copyQR}
              </button>
              <button
                className="btn btn-pill btn-outline-accent m-1"
                onClick={() => this.downloadQRCode()}
              >
                {locales_es.downloadQR}
              </button>
            </div>
            <div className="text-center kt-section__content kt-section__content--border">
              <CustomTooltip
                trigger="click"
                text={locales_es.copiedToClipboard}
                position="top"
                timeout="2000"
              >
                <a href={profileLink} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(profileLink);
                  window.initPopovers();
                }} className="btn btn-outline-hover-info btn-elevate btn-pill allow-user-select">{profileLink}</a>
              </CustomTooltip>
              {this.auth.getLocalUserType() === USER_TYPE_MEDIC &&
                <p className="mt-3"><small>Puede configurar un link más personalizado ajustando el nombre de tu cuenta
                  desde <a href={HREF_PAGE_MY_MEDIC_PROFILE}>tu perfil</a></small></p>}
            </div>
          </Modal>
        }
        {modalPatientShare &&
          <Modal modalId="shareProfileLink"
                 hideCloseButton={true}
                 title={locales_es.sharePatientLinkTitle}
                 visible={modalPatientShare}
                 actions={this.auth.getLocalUserType() === USER_TYPE_MEDIC ? [
                   {
                     className: 'btn btn-outline-brand btn-elevate btn-pill m-3 align-self-start',
                     title: locales_es.seeMyPatientShareLink,
                     onClick: () => {
                       window.open(profilePatientLink, '_blank');
                       this.setState({
                         modalPatientShare: false
                       })
                     }
                   },
                   {
                     className: 'btn btn-brand btn-elevate btn-pill m-3 align-self-start',
                     title: locales_es.accept,
                     onClick: () => {
                       this.setState({
                         modalPatientShare: false
                       })
                     }
                   }
                 ] : [
                   {
                     className: 'btn btn-brand btn-elevate btn-pill m-3 align-self-start',
                     title: locales_es.close,
                     onClick: () => {
                       this.setState({
                         modalPatientShare: false
                       })
                     }
                   }
                 ]}
          >
            <p>{locales_es.sharePatientLinkDescription}</p>
            <div className="text-center pb-3">
              <div id="qrCodeContainer" className="d-inline-block"></div>
            </div>
            <div className="text-center pb-3">
              <button
                className="btn btn-pill btn-outline-info m-1"
                onClick={() => this.copyQRToClipboard()}
              >
                {locales_es.copyQR}
              </button>
              <button
                className="btn btn-pill btn-outline-accent m-1"
                onClick={() => this.downloadQRCode()}
              >
                {locales_es.downloadQR}
              </button>
            </div>
            <div className="text-center kt-section__content kt-section__content--border">
              <CustomTooltip
                trigger="click"
                text={locales_es.copiedToClipboard}
                position="top"
                timeout="2000"
              >
                <a href={profilePatientLink} onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(profilePatientLink);
                }}
                   className="btn btn-outline-hover-info btn-elevate btn-pill allow-user-select">{profilePatientLink}</a>
              </CustomTooltip>
              {this.auth.getLocalUserType() === USER_TYPE_MEDIC &&
                <p className="mt-3"><small>Puede configurar un link más personalizado ajustando el nombre de tu cuenta
                  desde <a href={HREF_PAGE_MY_MEDIC_PROFILE}>tu perfil</a></small></p>}
            </div>
          </Modal>
        }
      </>
    )
  }
}
