import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Subheader from "../../components/subheader";
import locales_es from "../../locales/es";
import {
  HREF_PAGE_ADD_CERTIFICATE,
  HREF_PAGE_ADD_MEDICAL_INSURANCE_PATIENT, HREF_PAGE_ADD_PATIENT, HREF_PAGE_ADD_PRESCRIPTION,
  HREF_PAGE_ONLINE,
  HREF_PAGE_PATIENT,
  HREF_PAGE_PATIENT_INSURANCES,
  HREF_PAGE_PATIENTS,
  hrefDashboard, USER_TYPE_MEDIC,
  USER_TYPE_PATIENT, USER_TYPE_SECRETARY,
} from "../../models/constants";
import Loading from "../../components/loading";
import AuthService from "../../modules/authService";
import ConfigService from "../../modules/configService";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import Spinner from "../../components/spinner";
import FormEdit from "../../components/formEdit";
import Lottie from "react-lottie";
import animationDataMedicalInsurances from "../../lotties/insurances.json";
import InsurancesList from "../../components/insurancesList";
import OnlinePage from "../online";
import PrescriptionsPage from "../prescriptions";
import TabsStorage from "../../modules/TabsStorage";
import CertificatesPage from "../certificates";
import ContactButtons from "../../components/ContactButtons";
import MedicalRecords from "../../components/MedicalRecords/medicalRecords";
import MedicalStudies from "../../components/medicalStudies/medicalStudies";
import AppointmentsList from "../../components/appointmentsList";
import TimezoneService from "../../modules/timezoneService";

export default class PatientPage extends Component {

  constructor(props) {
    super(props);
    this.tabsStorage = new TabsStorage();
    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.auth = new AuthService();
    this.configService = new ConfigService();
    this.timezoneService = new TimezoneService();

    this.state = {
      loading: false,
      patientId: props.match.params.id,
      patient: null,
      medicalInfo: null,
      medicalInsurances: null,
      medicalComplementaryStudiesTypes: null,
      activeTab: this.tabsStorage.get(),
      timezoneOptions: [
        {value: 0, label: locales_es.loading},
      ],
      appointments: null,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('reload', this.getMedicalInsurances);
  }

  componentDidMount() {
    const isLoggedIn = this.auth.isLoggedUser();
    if (!isLoggedIn || this.auth.getLocalUserType() === USER_TYPE_PATIENT) {
      window.location.href = hrefDashboard;
    } else {
      this.api.getIdentificationTypes().then(identificationTypes => {
        this.api.getPatient(this.state.patientId).then(res => {
          const identificationType = identificationTypes.data.filter(type => Number(type.id) === Number(res.data.identification_type_id));
          this.setState({
            patient: res.data,
            identificationType: identificationType.length ? identificationType[0].name : null
          }, () => {
            this.api.getComplementaryStudiesTypes().then(types => {
              const studyTypeId = types.data.filter(type => type.name.toLowerCase() === 'otros').map(type => {
                return {
                  value: type.id,
                  label: type.name,
                }
              });
              this.setState({
                complementaryStudyType: studyTypeId.length ? studyTypeId[0] : null,
                medicalComplementaryStudiesTypes: types.data.map(type => {
                  return {
                    value: type.id,
                    label: type.name,
                  }
                })
              }, () => {
                if (this.auth.getLocalUserType() === USER_TYPE_MEDIC) {
                  this.getMedicalInfo();
                  this.getMedicalInsurances();
                }
                if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
                  this.getMedicalInsurances();
                }
                this.timezoneService.getRemoteParsedTimezones().then(res => {
                  this.setState({
                    timezoneOptions: res,
                    // timezone: res.filter(tz => tz.value === DEFAULT_TIME_ZONE)[0]
                  }, () => {
                    this.getPatientAppointments();
                  })
                }).catch(err => {
                  this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
                });
              });
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
              this.setState({
                patient: false,
              })
            });
          })
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setState({
            patient: false,
          })
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    }
    document.addEventListener('reload', this.getMedicalInsurances);
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  getMedicalInfo() {
    this.setState({
      medicalInfo: null
    }, () => {
      this.api.getMedicalInfo(this.state.patientId).then(res => {
        this.setState({
          medicalInfo: res.data
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    })
  }

  getMedicalInsurances() {
    this.api.getMedicalInsuranceByPatient(this.state.patientId).then(res => {
      this.setState({
        medicalInsurances: res.data,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  getPatientAppointments() {
    /*this.api.getPatientAppointments(this.state.patientId, this.state.patient.medic?.id).then(res => {
      this.setState({
        appointments: res.data
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });*/
    if (this.state.patient && this.state.patient.person_id) {
      this.api.getPersonAppointments(this.state.patient.person_id).then(res => {
        this.setState({
          appointments: res.data
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.clearAppointments();
      });
    } else if (this.state.patientId && this.state.patient.medic?.id) {
      this.api.getPatientAppointments(this.state.patientId, this.state.patient.medic?.id).then(res => {
        this.setState({
          appointments: res.data
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.clearAppointments();
      });
    }
  }

  clearAppointments() {
    this.setState({
      appointments: []
    });
  }

  onMedicalInfoEdit() {
    const inputs = [{
      label: locales_es.preexistingDiseases,
      placeholder: locales_es.medicalInfoNoData,
      preData: this.state.medicalInfo && this.state.medicalInfo.pre_existing_conditions ? this.state.medicalInfo.pre_existing_conditions : '',
      id: 1,
      state: 'pre_existing_conditions',
      type: 'textarea',
      required: false,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.chronicMedication,
      placeholder: locales_es.medicalInfoNoData,
      preData: this.state.medicalInfo && this.state.medicalInfo.chronic_medication ? this.state.medicalInfo.chronic_medication : '',
      id: 2,
      state: 'chronic_medication',
      type: 'textarea',
      required: false,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.habits,
      placeholder: locales_es.medicalInfoNoData,
      preData: this.state.medicalInfo && this.state.medicalInfo.habits ? this.state.medicalInfo.habits : '',
      id: 3,
      state: 'habits',
      type: 'textarea',
      required: false,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.familyBackground,
      placeholder: locales_es.medicalInfoNoData,
      preData: this.state.medicalInfo && this.state.medicalInfo.family_background ? this.state.medicalInfo.family_background : '',
      id: 4,
      state: 'family_background',
      type: 'textarea',
      required: false,
      wrapperCustomClassName: 'form-group col-12',
    },];

    const customStyles = {height: 'auto', maxHeight: 640, overflow: 'auto', maxWidth: '90%'}

    const onSubmit = (inputs) => {
      inputs.patient_id = this.state.patientId;
      this.api.putMedicalInfo(inputs).then(res => {
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.getMedicalInfo();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    }

    this.props.showMainModal('Editar Perfil Médico', <FormEdit id={this.state.patientId}
                                                               inputs={inputs}
                                                               onSubmit={onSubmit}
    />, customStyles, null);
  }

  setActiveTab = (tabName) => {
    this.setState({activeTab: tabName});
    this.tabsStorage.set(tabName);
  }

  removeMedicalInsurance(insuranceId) {
    this.props.showMainModal('¿Desea eliminar a este cobertura médica?', 'Esta acción no se puede deshacer.', null, [{
      label: 'Sí, eliminar', class: 'btn btn-danger btn-elevate btn-pill btn-sm', method: () => {
        this.api.deleteMedicalInsurancePatient(insuranceId).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalInsurances();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      }
    }, {
      label: 'No, no eliminar', class: 'btn btn-primary btn-elevate btn-pill btn-sm'
    }]);
  }

  render() {
    const {loading, patient, medicalInfo, medicalInsurances, timezoneOptions, appointments} = this.state;

    const animationSize = 150;
    const animationOptionsMedicalInsurances = {
      loop: true, autoplay: true, animationData: animationDataMedicalInsurances, rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (<>
      <Subheader breadcrumbs={[{
        name: locales_es.patients, href: HREF_PAGE_PATIENTS
      }, {
        name: locales_es.patientProfile, href: HREF_PAGE_PATIENT + '/' + this.state.patientId
      }, patient ? {
        name: patient.name + ' ' + patient.lastname
      } : {}]}/>
      <>
        {loading ? <Loading/> : null}
        {patient === null ? <Spinner/> : patient ? <>
            <div className="kt-portlet kt-widget kt-widget--fit kt-widget--general-3">
              <div className="position-relative">
                <a
                  href={`${HREF_PAGE_ADD_PATIENT}?redirect=${window.location.pathname}&medic_id=${this.auth.getUserData().user.id}&patientId=${patient.id}`}
                  title={locales_es.editProfile}
                  className="btn btn-clean btn-sm btn-icon btn-icon-md woopi-edit-float-right">
                  <i className="flaticon2-pen"></i>
                </a>
              </div>
              <div className="kt-portlet__body">
                <div className="kt-widget__top">
                  <div className="kt-media kt-media--xl kt-media--circle">
                    <img src={patient.full_profile_image} alt="image"/>
                  </div>
                  <div className="kt-widget__wrapper">
                    <div className="kt-widget__label">
                      <a href="#" className="kt-widget__title">
                        {patient.name} {patient.lastname}
                      </a>
                      {patient && patient.date_of_birth ? (
                        <>
                          <span
                            className="kt-widget__desc"><strong>{`${locales_es.age}: `}</strong>{this.viewHelpers.renderAge(patient.date_of_birth)}</span>
                                  <span className="kt-widget__desc"><strong>{`${locales_es.date_of_birth}: `}</strong>
                          <span className="kt-widget__value"
                                dangerouslySetInnerHTML={{
                                  __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'day') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'month') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(patient.date_of_birth), true, 'year')
                                }}/></span>
                        </>
                      ) : <span className="kt-widget__desc"><strong>{locales_es.noDateOfBirth}</strong></span>}
                      <span
                        className="kt-widget__desc"><strong>{`${this.state.identificationType || locales_es.identificationType}: `}</strong>{patient.identification}</span>
                    </div>
                    {((this.viewHelpers.userDataHasData(patient, 'email')) || patient.cellphone || (patient.user && patient.user.cellphone)) &&
                      <div className="kt-widget__links">
                        <div className="kt-widget__cont">
                          {this.viewHelpers.userDataHasData(patient, 'email') && !this.helpers.isFake(this.viewHelpers.userDataHasData(patient, 'email')) &&
                            <div className="kt-widget__link">
                              <i className="flaticon2-send  kt-font-success"></i><a
                              href={`mailto:${this.viewHelpers.userDataHasData(patient, 'email')}`}>{this.viewHelpers.userDataHasData(patient, 'email')}</a>
                            </div>}
                          {(this.viewHelpers.userDataHasData(patient, 'cellphone')) &&
                            <div className="kt-widget__link align-items-center">
                              <i className="flaticon2-phone"></i><a
                              href={`tel:${patient.cellphone ?? patient.user?.cellphone}`}>{patient.cellphone ?? patient.user?.cellphone}</a>
                              <span className="ml-3">
                          <ContactButtons patient={patient} size="small"/>
                        </span>
                            </div>}
                        </div>
                      </div>}
                  </div>
                </div>
                {patient.notes && <span
                  className="kt-widget__desc"><strong>{`${locales_es.notes}: `}</strong>{patient.notes}</span>}
              </div>
              <div className="kt-portlet__foot kt-portlet__foot--fit">
                <div className="kt-widget__nav ">
                  <ul
                    className="woopi-ul-hide-empty nav nav-tabs nav-tabs-space-xl nav-tabs-line nav-tabs-clear nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-portlet__space-x"
                    role="tablist">
                    {this.auth.getLocalUserType() === USER_TYPE_MEDIC && <li className="nav-item">
                      <a className={`nav-link ${this.state.activeTab === 'medicalRecord' ? 'active' : ''}`}
                         onClick={(e) => {
                           e.preventDefault();
                           this.setActiveTab('medicalRecord');
                         }}
                         href={`${HREF_PAGE_PATIENT}/${patient.id}`}>
                        <i className="flaticon2-calendar-3"></i> {locales_es.medicalRecord}
                      </a>
                    </li>}
                    {this.auth.getLocalUserType() === USER_TYPE_MEDIC && <li className="nav-item">
                      <a className={`nav-link ${this.state.activeTab === 'medicalStudies' ? 'active' : ''}`}
                         onClick={(e) => {
                           e.preventDefault();
                           this.setActiveTab('medicalStudies');
                         }}
                         href={`${HREF_PAGE_PATIENT}/${patient.id}`}>
                        <i className="flaticon2-layers-1"></i> {locales_es.medicalStudies}
                      </a>
                    </li>}
                    {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && <li className="nav-item">
                      <a className={`nav-link ${this.state.activeTab === 'medicalInsurances' ? 'active' : ''}`}
                         onClick={(e) => {
                           e.preventDefault();
                           this.setActiveTab('medicalInsurances');
                         }}
                         href={`${HREF_PAGE_PATIENT_INSURANCES}/${patient.id}`}>
                        <i className="flaticon2-medical-records"></i> {locales_es.medicalInsurances}
                      </a>
                    </li>}
                    {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && <li className="nav-item">
                      <a className={`nav-link ${this.state.activeTab === 'medicalPrescriptions' ? 'active' : ''}`}
                         href="#"
                         onClick={(e) => {
                           e.preventDefault();
                           this.setActiveTab('medicalPrescriptions');
                         }}>
                        <i className="flaticon2-contract"></i> {locales_es.prescriptions}
                      </a>
                    </li>}
                    {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && <li className="nav-item">
                      <a className={`nav-link ${this.state.activeTab === 'medicalCertificates' ? 'active' : ''}`}
                         href="#"
                         onClick={(e) => {
                           e.preventDefault();
                           this.setActiveTab('medicalCertificates');
                         }}>
                        <i className="flaticon2-document"></i> {locales_es.certificates}
                      </a>
                    </li>}
                    {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) &&
                      <li className="nav-item">
                        <a className={`nav-link ${this.state.activeTab === 'appointments' ? 'active' : ''}`}
                           href="#"
                           onClick={(e) => {
                             e.preventDefault();
                             this.setActiveTab('appointments');
                           }}>
                          <i className="flaticon2-document"></i> {locales_es.appointments}
                        </a>
                      </li>}
                    {((patient.user && this.helpers.isFake(patient.user.email)) || !patient.user) ? null :
                      this.auth.getLocalUserType() === USER_TYPE_MEDIC ? <li className="nav-item">
                        <a className={`nav-link ${this.state.activeTab === 'chat' ? 'active' : ''}`}
                           onClick={(e) => {
                             e.preventDefault();
                             this.setActiveTab('chat');
                           }}
                           href={`${HREF_PAGE_ONLINE}/${this.auth.getUserData().user.id}/${patient.user.id}`}>
                          <i className="flaticon2-chat-1"></i> Mensajes
                        </a>
                      </li> : null}
                  </ul>
                </div>
              </div>
            </div>

            {this.auth.getLocalUserType() === USER_TYPE_MEDIC && this.state.activeTab === 'medicalRecord' &&
              <div className="row">
                <div className="col-sm-12 col-lg-4 order-lg-1 order-xl-1">

                  <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">Perfil Médico</h3>
                      </div>
                      <div className="kt-portlet__head-toolbar">
                        <div className="kt-portlet__head-toolbar-wrapper">
                          <button onClick={() => {
                            this.onMedicalInfoEdit()
                          }} type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md">
                            <i className="flaticon2-pen"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="kt-portlet__body">
                      {medicalInfo === null ? <Spinner/> : medicalInfo ? <div className="kt-widget-16">
                        <div className="kt-widget-16__item">
                          <div className="kt-widget-16__labels">
                            <div className="kt-widget-16__title kt-font-success">{locales_es.preexistingDiseases}</div>
                            <div
                              className="kt-widget-16__desc">{medicalInfo.pre_existing_conditions || locales_es.medicalInfoNoData}</div>
                          </div>
                        </div>
                        <div className="kt-widget-16__item">
                          <div className="kt-widget-16__labels">
                            <div className="kt-widget-16__title kt-font-danger">{locales_es.chronicMedication}</div>
                            <div
                              className="kt-widget-16__desc">{medicalInfo.chronic_medication || locales_es.medicalInfoNoData}</div>
                          </div>
                        </div>
                        <div className="kt-widget-16__item">
                          <div className="kt-widget-16__labels">
                            <div className="kt-widget-16__title kt-font-warning">{locales_es.habits}</div>
                            <div className="kt-widget-16__desc">{medicalInfo.habits || locales_es.medicalInfoNoData}</div>
                          </div>
                        </div>
                        <div className="kt-widget-16__item">
                          <div className="kt-widget-16__labels">
                            <div className="kt-widget-16__title kt-font-info">{locales_es.familyBackground}</div>
                            <div
                              className="kt-widget-16__desc">{medicalInfo.family_background || locales_es.medicalInfoNoData}</div>
                          </div>
                        </div>
                        {medicalInfo.pre_existing_conditions || medicalInfo.chronic_medication || medicalInfo.habits || medicalInfo.family_background ?
                          <div className="kt-widget-16__item">
                            <div className="kt-widget-16__labels text-right">
                              <div className="kt-widget-16__title">Última modificación</div>
                              <div className="kt-widget-16__desc" dangerouslySetInnerHTML={{
                                __html: this.dateTimeService.parseEventDate(medicalInfo.updated_at, true, 'day') + ' ' + this.dateTimeService.parseEventDate(medicalInfo.updated_at, true, 'month') + ' ' + this.dateTimeService.parseEventDate(medicalInfo.updated_at, true, 'year') + ' ' + this.dateTimeService.parseEventTime(medicalInfo.updated_at, true)
                              }}/>
                            </div>
                          </div> : null}
                      </div> : <p>{locales_es.anErrorHasOcurred}</p>}
                    </div>
                  </div>

                </div>
                <div className="col-sm-12 col-lg-8 order-lg-2 order-xl-1 overflow-auto">
                  <MedicalRecords
                    patientId={this.state.patientId}
                    showMainModal={this.props.showMainModal}
                  />
                </div>

              </div>}
            {
              this.auth.getLocalUserType() === USER_TYPE_MEDIC && this.state.activeTab === 'medicalStudies' &&
              <div className="row">
                <div className="col">
                  <MedicalStudies
                    patientId={this.state.patientId}
                    showMainModal={this.props.showMainModal}
                  />
                </div>

              </div>
            }
            {
              (this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && this.state.activeTab === 'medicalInsurances' &&
              <div className="row">
                <div className="col-12">

                  <div className="kt-portlet kt-portlet--height-fluid">
                    {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{locales_es.medicalInsurances}</h3>
                      </div>
                      <a href={`${HREF_PAGE_ADD_MEDICAL_INSURANCE_PATIENT}/${this.state.patientId}`}
                         className="btn btn-label-brand btn-bold align-self-center">
                        <i className="flaticon2-add-1"/> {locales_es.addMedicalInsurance}
                      </a>
                    </div>}
                    <div className="kt-portlet__body">
                      <div className="kt-scroll" data-scroll="true">
                        {medicalInsurances === null ? <Spinner/> : medicalInsurances && medicalInsurances.length ?
                          <InsurancesList medicalInsurances={medicalInsurances}
                                          removeMedicalInsurance={(id) => this.removeMedicalInsurance(id)}/> : <div>
                            <Lottie
                              options={animationOptionsMedicalInsurances}
                              height={animationSize}
                              width={animationSize}
                            />
                            <div className="text-center">{locales_es.noMedicalInsurancesAvailables} <br/><br/></div>
                          </div>}
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            }
            {
              (this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && this.state.activeTab === 'medicalPrescriptions' &&
              <div className="row">
                <div className="col">

                  <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{locales_es.prescriptions}</h3>
                      </div>
                      {this.auth.getLocalUserType() === USER_TYPE_MEDIC && (
                        <a href={`${HREF_PAGE_ADD_PRESCRIPTION}/${this.state.patientId}`}
                           className="btn btn-label-brand btn-bold align-self-center">
                          <i className="flaticon2-add-1"/> {locales_es.createPrescription}
                        </a>
                      )}
                    </div>
                    <PrescriptionsPage showMainModal={this.props.showMainModal} patientId={this.state.patientId}
                                       history={this.props.history}/>
                  </div>
                </div>

              </div>
            }
            {
              (this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && this.state.activeTab === 'medicalCertificates' &&
              <div className="row">
                <div className="col">

                  <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{locales_es.addAMedicalCertificate}</h3>
                      </div>
                      {this.auth.getLocalUserType() === USER_TYPE_MEDIC && (
                        <a href={`${HREF_PAGE_ADD_CERTIFICATE}/${this.state.patientId}`}
                           className="btn btn-label-brand btn-bold align-self-center">
                          <i className="flaticon2-add-1"/> {locales_es.createCertificate}
                        </a>
                      )}
                    </div>
                    <CertificatesPage showMainModal={this.props.showMainModal} patientId={this.state.patientId}
                                      history={this.props.history}/>
                  </div>
                </div>

              </div>
            }
            {
              (this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) && this.state.activeTab === 'appointments' &&
              <div className="row">
                <div className="col">

                  <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{locales_es.appointments}</h3>
                      </div>
                    </div>
                    {appointments === null ? <Spinner/> : appointments && appointments.length > 0 ?
                      <AppointmentsList appointments={appointments}
                                        timezoneOptions={timezoneOptions}
                                        refresh={() => this.getPatientAppointments()}
                                        showDelete={true}
                                        showVideocall={true}
                                        showAddress={true}
                                        showInfo={true}
                                        showMainModal={this.props.showMainModal}/>
                      : <div className="m-3 text-center">{locales_es.thisPatientHasNoAppointmentsYet}</div>}
                  </div>
                </div>

              </div>
            }
            {
              this.auth.getLocalUserType() === USER_TYPE_MEDIC && this.state.activeTab === 'chat' && <div className="row">
                <div className="col-12">
                  <div className="kt-portlet">
                    <div className="kt-portlet__body" style={{minHeight: '50vh'}}>
                      <div className="kt-scroll" style={{minHeight: '50vh'}}>
                        <OnlinePage medicId={this.auth.getUserData().user.id} patientId={patient.user.id}
                                    componentMode={true}/>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            }
          </> :
          <h1 className="text-center p-5">No se han encontrado datos.</h1>
        }

      </>
    </>)
  }
}
