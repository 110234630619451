import {useState} from 'react';
import {HREF_PAGE_PATIENT_ADDRESSES} from "../../models/constants";
import locales_es from "../../locales/es";
import AddressSearch from "../../components/addressSearch/addressSearch";
import Form from '../../components/form';
import {useAddressContext} from "../../context/AddressContext";

const AddUserAddressForm = ({addressId, formData: initialFormData, initialDefaultLocationMarker}) => {

    const {updateAddress, createAddress} = useAddressContext();
    const [defaultLocationMarker, setDefaultLocationMarker] = useState(initialDefaultLocationMarker);
    const [formData, setFormData] = useState(
        initialFormData || {
            name: "",
            street: "",
            number: "",
            floor: "",
            apartment: "",
            city: "",
            state: "",
            zip_code: "",
            country: "",
            lat: "",
            lng: "",
        }
    );

    const handleAddressSelect = (address) => {
        // Extraer la información de la dirección seleccionada
        if (address && address.placeDetails) {
            const {placeDetails} = address;
            const streetName = placeDetails.find(detail => detail.types.includes('route'));
            const streetNumber = placeDetails.find(detail => detail.types.includes('street_number'));
            const city = placeDetails.find(detail => detail.types.includes('locality'));
            const state = placeDetails.find(detail => detail.types.includes('administrative_area_level_1'));
            const zipCode = placeDetails.find(detail => detail.types.includes('postal_code'));
            const country = placeDetails.find(detail => detail.types.includes('country'));

            // Actualizar los datos del formulario con la información extraída
            setFormData({
                ...formData,
                street: streetName ? streetName.long_name : '',
                number: streetNumber ? streetNumber.long_name : '',
                city: city ? city.long_name : '',
                state: state ? state.long_name : '',
                zip_code: zipCode ? zipCode.long_name : '',
                country: country ? country.long_name : '',
                lat: address.lat,
                lng: address.lng
            });
            setDefaultLocationMarker({
                lat: address.lat,
                lng: address.lng
            });
        }
    };

    const handleChange = (state) => (event) => {
        // Actualizar el estado del formulario con el valor del input
        setFormData({
            ...formData,
            [state]: event.target.value,
        });
    };

    const handleFormCancel = () => {
        window.location.href = `${HREF_PAGE_PATIENT_ADDRESSES}`;
    };

    const handleFormSubmit = async () => {
        try {
            addressId ? await updateAddress(addressId, formData) : await createAddress(formData);
        } catch (error) {
            console.error(error);
        }
    };

    const inputs = [
        {
            label: locales_es.userAddressesForm.Name,
            placeholder: locales_es.userAddressesForm.NamePlaceHolder,
            id: 1,
            state: "name",
            value: formData.name,
            type: "text",
            required: true,
            wrapperCustomClassName: "form-group col-md-12 pl-md-0 pr-md-0",
        },
        {
            label: locales_es.userAddressesForm.Street,
            placeholder: "",
            id: 2,
            state: "street",
            value: formData.street,
            type: "text",
            required: true,
            wrapperCustomClassName: "form-group col-md-6 float-left pl-md-0",
        },
        {
            label: locales_es.userAddressesForm.Number,
            placeholder: "",
            id: 3,
            state: "number",
            value: formData.number,
            type: "number",
            required: true,
            wrapperCustomClassName: "form-group col-md-2 float-left pr-md-0",
        },
        {
            label: locales_es.userAddressesForm.Floor,
            placeholder: "",
            id: 4,
            state: "floor",
            value: formData.floor,
            type: "text",
            required: false,
            wrapperCustomClassName: "form-group col-md-2 float-left pr-md-0",
        },
        {
            label: locales_es.userAddressesForm.Apartment,
            placeholder: "",
            id: 5,
            state: "apartment",
            value: formData.apartment,
            type: "text",
            required: false,
            wrapperCustomClassName: "form-group col-md-2 float-left pr-md-0",
        },
        {
            label: locales_es.userAddressesForm.City,
            placeholder: "",
            id: 6,
            state: "city",
            value: formData.city,
            type: "text",
            required: true,
            wrapperCustomClassName: "form-group col-md-6 float-left pl-md-0",
        },
        {
            label: locales_es.userAddressesForm.State,
            placeholder: "",
            id: 7,
            state: "state",
            value: formData.state,
            type: "text",
            required: true,
            wrapperCustomClassName: "form-group col-md-6 float-left pr-md-0",
        },
        {
            label: locales_es.userAddressesForm.ZipCode,
            placeholder: "",
            id: 8,
            state: "zip_code",
            value: formData.zip_code,
            type: "text",
            required: true,
            wrapperCustomClassName: "form-group col-md-4 float-left pl-md-0",
        },
        {
            label: locales_es.userAddressesForm.Country,
            placeholder: "",
            id: 9,
            state: "country",
            value: formData.country,
            type: "text",
            required: true,
            wrapperCustomClassName: "form-group col-md-8 float-left pr-md-0",
        }
    ];

    return (
        <>
            <Form
                styles="kt-form"
                inputs={inputs}
                handleChange={handleChange}
                onSubmit={handleFormSubmit}
                onSubmitButtonText={locales_es.send}
                secondaryButtonText={locales_es.cancel}
                onClickSecondaryButton={handleFormCancel}
                showTerms={false}
                wrapper={true}
            >
                <AddressSearch onAddressSelect={handleAddressSelect} defaultLocationMarker={defaultLocationMarker}/>
            </Form>
        </>


    );
};

export default AddUserAddressForm;
