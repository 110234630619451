import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Spinner from "../../components/spinner";
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import MedicalRecord from "../../components/medicalRecord";
import Lottie from "react-lottie";
import animationData from "../../lotties/no-medical-records.json";
import DateTimeService from "../../modules/DateTimeService";
import FormEdit from "../formEdit";
import ConfigService from "../../modules/configService";
import {
  CLINIC_PATIENTS_HANDLE_SHARE, EVENT_SHOW_UPGRADE_MODAL, HREF_PAGE_SETTINGS, MEDICLOUD_LOGO_ONLINE
} from "../../models/constants";

export default class MedicalRecords extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false, medicalRecordTypes: [], medicalRecords: null, medicalRecordsNextPageUrl: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
    this.configService = new ConfigService();
  }

  componentDidMount() {
    this.getMedicalRecords();
    this.getMedicalRecordsViewers();
  }

  getMedicalRecords(pageUrl = null) {
    return this.api.getMedicalRecordsByPatient(this.props.patientId, pageUrl).then(res => {
      this.setState({
        medicalRecords: this.state.medicalRecords && pageUrl ? this.state.medicalRecords.concat(res.data) : res.data,
        medicalRecordsPerPage: res.per_page,
        medicalRecordsNextPageUrl: res.next_page_url,
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getMedicalRecordsViewers() {
    this.configService.getLocalClinicData().then(clinic => {
      if (clinic && clinic.patients_handle === CLINIC_PATIENTS_HANDLE_SHARE) {
        this.api.getMedicalRecordsViewersByPatient(this.props.patientId).then(res => {
          this.setState({
            viewers: res.data
          }, () => window.initTooltips());
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      }
    }).catch(err => {
      console.error(err);
    });
  }

  onAddMedicalRecord(medicalRecord) {
    const inputs = [{
      label: locales_es.title,
      placeholder: locales_es.medicalRecordTitlePlaceholder,
      id: 1,
      state: 'title',
      preData: medicalRecord ? medicalRecord.title : '',
      type: 'text',
      required: true,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.description,
      placeholder: locales_es.medicalRecordDescriptionPlaceholder,
      id: 2,
      state: 'text',
      preData: medicalRecord ? medicalRecord.text : '',
      type: 'textarea',
      required: true,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: 'Notas Privadas',
      placeholder: locales_es.medicalRecordInternalNotesPlaceholder,
      id: 3,
      state: 'internal_notes',
      preData: medicalRecord ? medicalRecord.internal_notes : '',
      type: 'textarea',
      required: false,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.date,
      placeholder: locales_es.date,
      id: 10,
      state: '_date',
      preData: medicalRecord ? this.dateTimeService.parseAPIStringToDate(medicalRecord.date) : '',
      type: 'date',
      required: false,
      wrapperCustomClassName: 'form-group col-12 col-md-4 float-left',
      maxDate: new window.Date().getTime(),
    }, {
      label: locales_es.weight,
      placeholder: locales_es.medicalRecordWeightPlaceholder,
      id: 4,
      state: 'weight',
      preData: medicalRecord ? medicalRecord.weight : '',
      type: 'number',
      required: false,
      step: '0.01',
      wrapperCustomClassName: 'form-group col-6 col-md-4 float-left',
    }, {
      label: locales_es.height,
      placeholder: locales_es.medicalRecordHeightPlaceholder,
      id: 5,
      state: 'height',
      preData: medicalRecord ? medicalRecord.height : '',
      type: 'number',
      required: false,
      step: '0.01',
      wrapperCustomClassName: 'form-group col-6 col-md-4 float-left',
    }];

    const customStyles = {height: 'auto', maxHeight: 640, overflow: 'auto', maxWidth: '90%'}

    const onSubmit = (inputs) => {
      // API hardcoded for now
      inputs.medical_record_type_id = 1; // TODO REMOVE?
      inputs.patient_id = this.props.patientId;
      inputs.date = this.dateTimeService.parseDateToAPIString(new Date(inputs._date));

      this.setLoading(true);
      if (medicalRecord && medicalRecord.id) {
        this.api.putMedicalRecord(medicalRecord.id, inputs).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalRecords();
          this.setLoading(false);
        }).catch(err => {
          this.setLoading(false);
          alert(this.helpers.getErrorMsg(err));
        })
      } else {
        this.api.postMedicalRecord(inputs).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalRecords();
          this.setLoading(false);
        }).catch(err => {
          this.setLoading(false);
          alert(this.helpers.getErrorMsg(err));
        })
      }
    }

    this.props.showMainModal(locales_es.addMedicalRecord, <FormEdit inputs={inputs}
                                                                    onSubmitButtonText={locales_es.save}
                                                                    onSubmit={onSubmit}
    />, customStyles, null);
  }

  onRemoveMedicalRecord(recordId) {
    this.props.showMainModal(locales_es.confirmActionModal.title, locales_es.confirmActionModal.subtitle, null, [{
      label: locales_es.confirmActionModal.accept, class: 'btn btn-danger', method: () => {
        this.api.deleteMedicalRecord(recordId).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalRecords();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      }
    }, {
      label: locales_es.confirmActionModal.cancel, class: 'btn btn-brand',
    }]);
  }

  setLoading(bool) {
    this.setState({loading: bool});
  }

  handleCheckboxChange(event, typeId) {
    const checkbox = event.target;
    const isChecked = checkbox.checked;

    // Buscar el checkbox correspondiente en el DOM y actualizar su estado
    const pdfTypes = document.getElementById('pdf-types');
    const checkboxes = pdfTypes.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(checkbox => {
      if (parseInt(checkbox.dataset.typeId, 10) === typeId) {
        checkbox.checked = isChecked;
      }
    });
  }

  showExportPDFModal() {
    this.setLoading(true);

    Promise.all([this.api.getMedicalRecordTypes(), this.api.getMyPrescriptionProfiles()])
      .then(([medicalRecordTypesRes, prescriptionProfilesRes]) => {
        this.setLoading(false);

        const medicalRecordTypes = medicalRecordTypesRes.data.map(type => ({
          ...type, checked: type.id === 1 ?? false
        }));

        // Agregar la opción "Logo de Medicloud" como primera opción
        const prescriptionProfiles = [{
          id: "medicloud-logo",
          name: "Logo de Medicloud",
          full_logo_image: MEDICLOUD_LOGO_ONLINE
        }, ...prescriptionProfilesRes.data];

        const content = `
      <div id="pdf-types">
        <p>${locales_es.exportPDFMedicalRecordSubtitle}</p>

        <div class="text-left">
          ${medicalRecordTypes.map(type => `
            <div class="row mt-3" key="medical-record-type-${type.id}">
              <div class="col">
                <label class="kt-checkbox">
                  <input
                    type="checkbox"
                    ${type.checked ? 'checked' : ''}
                    data-type-id="${type.id}"
                    onchange="handleCheckboxChange(event, ${type.id})"
                  />
                  <span></span> ${type.name}
                </label>
              </div>
            </div>
          `).join('')}
        </div>

        <div class="text-left mt-4">
          <p><strong>Seleccionar perfil de prescripción:</strong></p>
          <select class="form-control" id="prescription-profile-select">
            ${prescriptionProfiles.map(profile => `
              <option value="${profile.id}" data-profile='${JSON.stringify(profile)}'>
                ${profile.name}
              </option>
            `).join('')}
          </select>
        </div>

        <div id="prescription-profile-preview" class="mt-4"></div>
      </div>
    `;

        this.props.showMainModal(locales_es.exportPDFMedicalRecord, content, null, [{
          label: locales_es.export, class: 'btn btn-brand', method: () => this.exportPDF()
        }, {
          label: locales_es.confirmActionModal.cancel, class: 'btn btn-outline-brand',
        }]);

        // Esperar a que el modal se renderice y asignar eventos
        setTimeout(() => {
          const selectElement = document.getElementById("prescription-profile-select");
          if (selectElement) {
            selectElement.addEventListener("change", this.handleProfileChange.bind(this));
            this.handleProfileChange({target: selectElement}); // Cargar el primer perfil por defecto
          }
        }, 100);
      })
      .catch(err => {
        this.setLoading(false);
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
  }

  /**
   * Maneja el cambio en el selector de perfiles y actualiza la vista previa en el modal con JavaScript puro.
   */
  handleProfileChange(event) {
    const selectedOption = event.target.selectedOptions[0];
    if (!selectedOption) {
      return;
    }
    const profileData = JSON.parse(selectedOption.dataset.profile);
    const profilesLink = HREF_PAGE_SETTINGS + '#tab3';

    const previewContainer = document.getElementById("prescription-profile-preview");
    if (previewContainer) {
      if (profileData.full_logo_image) {
        // Si el perfil tiene imagen, la mostramos
        previewContainer.innerHTML = `
                <div>
                    <div class="card p-3 text-center">
                        <img src="${profileData.full_logo_image}" alt="Logo del perfil" style="max-width: 100%; height: auto;">
                    </div>
                    <p class="mt-1"><a id="edit-profiles-link" href="${profilesLink}" class="kt-link kt-link--brand cursor-pointer">${locales_es.addNewProfile}</a></p>
                </div>
            `;
      } else {
        // Si no tiene imagen, mostramos un mensaje de advertencia
        previewContainer.innerHTML = `
                <div>
                    <strong>Este perfil no tiene una imagen de logo.</strong>
                    <p>No se puede usar este perfil para exportar el PDF.</p>
                    <p><a id="edit-profiles-link" href="${profilesLink}" class="kt-link kt-link--brand cursor-pointer">${locales_es.addNewProfile}</a></p>
                </div>
            `;
      }

      const checkUserPlan = async () => {
        try {
          const myPlanRes = await this.api.getMyPlan();
          const userPlan = myPlanRes.data;
          const hasSupportFeature = userPlan.features.some(feature => feature.tag === 'medical-records-templates');
          if (!hasSupportFeature) {
            const event = new CustomEvent(EVENT_SHOW_UPGRADE_MODAL, {
              detail: {
                title: '¡Actualiza tu plan!',
                message: 'Con el PRO y TEAM, puedes agregar tus propios logos a la Historia Clínica.', // plans: [PLAN_TAGS.PRO, PLAN_TAGS.ENTERPRISE],
              },
            });
            window.dispatchEvent(event);
          }
        } catch (error) {
          console.error('Error al obtener el plan del usuario:', error);
        }
      };

      // Disparar checkUserPlan si el perfil seleccionado no es "Logo de Medicloud"
      if (selectedOption.value !== "medicloud-logo") {
        checkUserPlan();
      }

      // Asignar evento al enlace después de haber insertado el HTML
      setTimeout(() => {
        const editLink = document.getElementById("edit-profiles-link");
        if (editLink) {
          editLink.addEventListener("click", (e) => {
            e.preventDefault();
            window.location.href = HREF_PAGE_SETTINGS + '#tab3';
          });
        }
      }, 100);
    }
  }

  exportPDF() {
    this.setLoading(true);
    const pdfTypes = document.getElementById('pdf-types');
    const checkboxes = pdfTypes.querySelectorAll('input[type="checkbox"]');
    const selectedTypes = Array.from(checkboxes)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => parseInt(checkbox.dataset.typeId, 10));

    // Obtener el valor seleccionado del select de perfiles
    const selectElement = document.getElementById("prescription-profile-select");
    let selectedProfileId = null;

    if (selectElement) {
      selectedProfileId = selectElement.value !== "medicloud-logo" ? parseInt(selectElement.value, 10) : null;
    }

    this.configService.getLocalClinicData().then(clinic => {
      const objData = {
        types_ids: selectedTypes
      };

      if (clinic && clinic.id) {
        objData.clinic_id = clinic.id;
      }

      if (selectedProfileId !== null) {
        objData.prescription_profile_id = selectedProfileId;
      }

      this.api.getMedicalRecordExport(this.props.patientId, objData).then(res => {
        this.setLoading(false);
        if (res.data && res.data.url) {
          window.open(res.data.url, '_blank');
        }
      }).catch(err => {
        this.setLoading(false);
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    }).catch(err => {
      this.setLoading(false);
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }


  render() {
    const {loading, medicalRecords, medicalRecordsPerPage, medicalRecordsNextPageUrl, viewers} = this.state;

    const animationOptions = {
      loop: true, autoplay: true, animationData: animationData, rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const animationSize = 150;

    return (<>
      {loading ? <Spinner/> : null}
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          {viewers && viewers.length ? (<div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title d-none d-lg-inline-block">{locales_es.medicalRecord}</h3>
            <div className="kt-media-group ml-lg-3">
              {viewers.slice(0, 5).map((viewer, index) => (<span
                key={index}
                className="kt-media kt-media--sm kt-media--circle"
                data-toggle="tooltip"
                data-skin="brand"
                data-placement="top"
                title=""
                data-original-title={`${viewer.prefix_name ?? locales_es.medicPrefix} ${viewer.name} ${viewer.lastname}`}
              >
                    <img src={viewer.full_profile_image} alt="Avatar"/>
                    </span>))}
              {viewers.length > 5 && (<span
                className="kt-media kt-media--sm kt-media--circle"
                data-toggle="tooltip"
                data-skin="brand"
                data-placement="top"
                title=""
                data-original-title={viewers.slice(5).map(v => `${v.prefix_name ?? locales_es.medicPrefix} ${v.name} ${v.lastname}`).join(', ')}
              >
                      <span>+{viewers.length - 5}</span>
                    </span>)}
              <span
                className="kt-media kt-media--sm kt-media--circle bg-warning"
                data-toggle="tooltip"
                data-skin="brand"
                data-placement="top"
                title=""
                data-original-title="Estos son todos los médicos que pueden ver esta Historia Clínica"
              >
                      <span><i className="fa fa-info text-white"/></span>
                    </span>
            </div>
          </div>) : (<div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{locales_es.medicalRecord}</h3>
          </div>)}

          <div className="align-self-center">
            <a onClick={(ev) => {
              ev.preventDefault();
              this.showExportPDFModal();
            }} href="#" className="btn btn-label-danger btn-bold mr-2">
              <i className="flaticon2-file p-0 pr-md-2"/><span
              className="d-none d-md-inline"> {locales_es.exportPDFMedicalRecord}</span>
            </a>
            <a onClick={(ev) => {
              ev.preventDefault();
              this.onAddMedicalRecord();
            }} href="#" className="btn btn-label-brand btn-bold">
              <i className="flaticon2-add-1 p-0 pr-md-2"/><span
              className="d-none d-md-inline">{locales_es.addMedicalRecord}</span>
            </a>
          </div>
        </div>
        <div className="kt-portlet__body">
          {medicalRecords === null ? <Spinner/> : medicalRecords && medicalRecords.length ? (<InfiniteScroll
            dataLength={medicalRecordsPerPage}
            next={() => this.getMedicalRecords(medicalRecordsNextPageUrl)}
            hasMore={Boolean(medicalRecordsNextPageUrl)}
            loader={<Spinner/>}
            endMessage={<p className="text-center">
              <b>{locales_es.noMoreRecords}</b>
            </p>}
          >
            {medicalRecords.map(record => (<MedicalRecord
              key={record.id}
              record={record}
              onEditMedicalRecord={(record) => this.onAddMedicalRecord(record)}
              onRemoveMedicalRecord={(recordId) => this.onRemoveMedicalRecord(recordId)}
              showMainModal={this.props.showMainModal}
            />))}
          </InfiniteScroll>) : (<div className="text-center">
            <Lottie
              options={animationOptions}
              height={animationSize}
              width={animationSize}
            />
            <p>{locales_es.medicalRecordsNotFoundYet}</p>
            <p>
              <a href="#" onClick={(ev) => {
                ev.preventDefault();
                this.onAddMedicalRecord();
              }} className="btn btn-brand btn-upper btn-bold kt-align-center">
                {locales_es.addMedicalRecord}
              </a>
            </p>
          </div>)}
        </div>
      </div>
    </>);
  }
}
