import {useEffect, useState} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import {
    HREF_PAGE_ADD_PRESCRIPTION,
    HREF_PAGE_DOCUMENT_VISOR,
    HREF_PAGE_HOME,
    HREF_PAGE_PATIENT_ADDRESSES_ADD,
    HREF_PAGE_PRESCRIPTIONS,
    HREF_PAGE_VIEW_PRESCRIPTION_HASH,
    MEDICAL_PRESCRIPTION_TYPE,
    MODAL_TYPE,
    PAYMENT_STATUS_PENDING,
    USER_TYPE_MEDIC,
    USER_TYPE_PATIENT,
    USER_TYPE_SECRETARY,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import Subheader from "../../components/subheader";
import pdfIcon from '../../assets/images/pdf.svg';
import {TablePaging} from "../../components/TablePaging";
import ProfileImageDefault from '../../images/profile_image_default.jpg'
import {useAuthContext} from "../../context/AuthContext";
import {useAddressContext} from "../../context/AddressContext";
import {useModalContext} from "../../context/ModalContext";
import Loading from "../../components/loading";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import IframeWithHtmlContent from "../../components/iframe/IframeWithHtmlContent";

// **************************************
// **************************************
// **************************************
// *************IMPORTANTE***************
// **************************************
// **************************************
// **************************************
// Esta vista es para sólo para PACIENTES

const MyPrescriptionsPage = (props) => {
    const [prescriptions, setPrescriptions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [apiPatients, setApiPatients] = useState(null);
    const [pagingData, setPagingData] = useState(null);

    const api = new APIService();
    const helpers = new Helpers();
    const dateTimeService = new DateTimeService();

    const {userType, isLoadingAuth} = useAuthContext();
    const {addresses, isLoadingAddress} = useAddressContext();
    const {dispatch} = useModalContext();

    useEffect(() => {
        if (!isLoadingAuth) {
            if (userType !== USER_TYPE_PATIENT) {
                window.location.href = HREF_PAGE_HOME;
            }
        }
    }, [isLoadingAuth, userType]);

    useEffect(() => {
        getMyPrescriptions();

        const root = document.getElementById('root');
        if (helpers.isMobile() && root) {
            root.addEventListener('scroll', handleScroll);
        }

        return () => { // Esta función se ejecuta como componentWillUnmount
            const root = document.getElementById('root');
            if (helpers.isMobile() && root) {
                root.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleScroll = () => {
        const root = document.getElementById('root');
        const {scrollTop, scrollHeight, clientHeight} = root;
        if ((scrollTop + clientHeight >= scrollHeight - 50) && apiPatients !== null) {
            // you're at the bottom of the page
            if (!refreshing && pagingData && pagingData.next_page_url) {
                getMyPrescriptions(null, pagingData.next_page_url);
            }
        }
    };

    const getMyPrescriptions = (params, pageUrl) => {
        setRefreshing(true);
        api.getMyPrescriptionsOrPatientPrescriptions(params, pageUrl, props.patientId).then(res => {
            const pagingData = JSON.parse(JSON.stringify(res));
            delete pagingData.data;
            setPrescriptions(prevPrescriptions => prevPrescriptions ? prevPrescriptions.concat(res.data) : res.data);
            setApiPatients(prevApiPatients => prevApiPatients ? prevApiPatients.concat(res.data) : res.data);
            setPagingData(pagingData);
            setRefreshing(false);
        }).catch(err => {
            props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        });
    };

    const renderMedicine = (item) => {
        return (<div key={item.id} className={item.no_replacement ? 'woopi-medication-line-through' : ''}>
            {`${item.quantity} x ${item.is_generic ? locales_es.generic : (' ' + item.commercial_name)}`}
            ({item.drug}) {item.presentation}
        </div>)
    };

    const loadPrescription = (prescription) => {
        if (prescription.payment_amount && prescription.payment_status === PAYMENT_STATUS_PENDING) {
            window.open(`${HREF_PAGE_VIEW_PRESCRIPTION_HASH}/${prescription.hash}`, '_blank');
        } else {
            props.history.push(HREF_PAGE_DOCUMENT_VISOR, {
                documents: [{
                    pdfSource: {uri: prescription.full_file_url}, prescriptionId: prescription.id,
                },],
                prescriptionType: MEDICAL_PRESCRIPTION_TYPE,
                emails: prescription.patient && prescription.patient?.user && prescription.patient?.user.email ? [prescription.patient?.user.email] : null,
            });
        }
    };

    const repeatPrescription = (prescription) => {
        props.history.push(HREF_PAGE_ADD_PRESCRIPTION, {
            patientId: prescription.patient_id,
            patientMedicalInsuranceId: prescription.medical_insurance_user_patient_id,
            prescriptionProfileId: prescription.prescription_profile_id,
            professionalRegistrationId: prescription.professional_registration_id,
            medicines: prescription.medicines.map(medicine => {
                medicine.presentation = {
                    name: medicine.presentation
                };
                return medicine;
            }),
            diagnostic: prescription.diagnostic,
            indications: prescription.indications,
            comments: prescription.comments,
            isHiv: prescription.is_hiv,
        });
    };

    const onChangeTablePaging = (params, pageUrl) => {
        setPrescriptions(null);
        getMyPrescriptions(params, pageUrl);
    };

    const renderMedicationItem = (prescription) => {
        return (prescription.medicines.map(medicine => renderMedicine(medicine)))
    };

    const isPrescriptionExpired = (prescriptionDate) => {
        if (!prescriptionDate) {
            return true;
        }
        const today = new Date();
        const prescription = new Date(prescriptionDate);
        // Comprobar si la receta es futura
        if (prescription > today) {
            return true;
        }
        // Calcular la diferencia en días entre la fecha de creación y la fecha actual
        const diffTime = Math.abs(today - prescription);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays > 30;
    };

    const goToAddAddressPage = () => {
        window.location.href = `${HREF_PAGE_PATIENT_ADDRESSES_ADD}`;
    };

    const showModalNoAddressesNotification = () => {
        dispatch({
            type: "OPEN_MODAL",
            modalName: "showModalNoAddressesNotification",
            modalType: MODAL_TYPE.STANDARD,
            payload: {
                modalId: "showModalNoAddressesNotification",
                title: locales_es.myPrescriptionsCheckout.dontHaveDirectionsModal,
                hideCloseButton: false,
                actions: [{
                    title: locales_es.myPrescriptionsCheckout.buttonNewRecipeModal,
                    className: "btn btn-primary",
                    closeOnClick: true,
                    onClick: () => {
                        goToAddAddressPage();
                    }
                }],
                children: (<>
                    <p>{locales_es.myPrescriptionsCheckout.dontHaveDirectionsModalSub}</p>
                </>)
            }
        });
    };

    const showModalErrorNotification = (modalName, titleFailed) => {
        dispatch({
            type: "OPEN_MODAL",
            modalName: modalName,
            modalType: MODAL_TYPE.RESULT,
            payload: {
                result: "failed",
                titleFailed: titleFailed,
            }
        });
    }

    const showModalDelfarEmbedUrlContent = (htmlContent) => {
        dispatch({
            type: "OPEN_MODAL",
            modalName: "showModalDelfarEmbedUrlContent",
            modalType: MODAL_TYPE.STANDARD,
            payload: {
                modalId: "showModalDelfarEmbedUrlContent",
                title: locales_es.myPrescriptionsCheckout.checkoutRecipeStateModelTitle,
                width: 'lg',
                height: '80vh',
                heightBody: '80vh',
                hideCloseButton: false,
                actions: [],
                children: (
                    <>
                        <IframeWithHtmlContent htmlContent={htmlContent}/>
                    </>
                )
            }
        });
    };

    const handleDelfarEmbedUrl = async (url) => {
        setLoading(true);
        try {
            const htmlContent = await helpers.fetchUrlContent(url);
            if (htmlContent) {
                showModalDelfarEmbedUrlContent(htmlContent);
            } else {
                showModalErrorNotification("fetchDelfarUrlContent", "Error al obtener el contenido del pedido.");
            }
        } catch (error) {
            console.error("Error al mostrar el estado del pedido:", error);
            showModalErrorNotification("fetchDelfarUrlContent", helpers.getErrorMsg(error));
        } finally {
            setLoading(false);
        }
    };

    const showModalWithAddressesOptions = (prescriptionId) => {
        const modalOptions = addresses.map((address) => ({
            id: address.id,
            title: `${address.name}`,
            price: null,
            description: `${address.street} ${address.number}, ${address.city}, ${address.state}, ${address.country}`,
        }));

        dispatch({
            type: "OPEN_MODAL",
            modalName: "SelectOptionModal",
            modalType: MODAL_TYPE.OPTION,
            payload: {
                title: locales_es.myPrescriptionsCheckout.selectAddressModal,
                description: locales_es.myPrescriptionsCheckout.selectAddressModalSub,
                options: modalOptions,
                onSelect: (addressId) => handleRecipeCheckoutDelfar(addressId, prescriptionId)
            }
        });
    }

    const handleRecipeCheckoutAddresses = (prescriptionId) => {
        if (!addresses || addresses.length === 0) {
            showModalNoAddressesNotification();
        } else {
            showModalWithAddressesOptions(prescriptionId);
        }
    };

    const handleRecipeCheckoutDelfar = async (addressId, prescriptionId) => {
        const data = {
            "medical_prescription_id": prescriptionId,
            "address_id": addressId
        };
        setLoading(true);
        try {
            await api.postMedicalPrescription(data);
        } catch (error) {
            console.error("Error al procesar la receta:", error);
            showModalErrorNotification("CheckoutDelfarErrorModal", helpers.getErrorMsg(error));
        } finally {
            setLoading(false);
        }
    };

    if (loading || isLoadingAddress) {
        return <Loading/>;
    }

    return (<>
        <Subheader breadcrumbs={[{
            name: locales_es.prescriptions, href: HREF_PAGE_PRESCRIPTIONS
        }]}/>

        <>
            {prescriptions === null ? <Loading/> : prescriptions && prescriptions.length ? <div className="row">
                <div className="col-12">
                    {helpers.isMobile() ? <>
                        <div className="row">
                            {prescriptions.map((prescription) => {
                                return (<div key={`pcard-${prescription.id}`} className="col-xl-4 col-lg-6">
                                    <div className="kt-portlet">
                                        <div className="kt-widget kt-widget--general-2">
                                            <div className="kt-portlet__body kt-portlet__body--fit">
                                                <span className="kt-widget__top">
                                                    <div className="kt-media kt-media--lg">
                                                        <img src={prescription.patient?.full_profile_image || ProfileImageDefault} alt=""/>
                                                    </div>
                                                    <div className="kt-widget__wrapper">
                                                        <div className="kt-widget__label">
                                                            <span className="kt-link kt-link--success">{locales_es.createdPrescription}</span>
                                                            <span className="kt-widget__title">
                                                                {prescription.patient?.name || prescription.patient?.lastname ? `${prescription.patient?.name} ${prescription.patient?.lastname}` : locales_es.deletedPatient}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                                <div className="kt-widget__bottom d-block">
                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            <div
                                                                className="kt-widget__caption">{locales_es.creationDate}:
                                                            </div>
                                                            {prescription.created_at &&
                                                                <span className="kt-widget__value"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: dateTimeService.parseEventDate(prescription.created_at, true, 'full-string')
                                                                    }}/>}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-auto">
                                                            <a onClick={(e) => {
                                                                e.preventDefault();
                                                                loadPrescription(prescription);
                                                            }}>
                                                                <img alt="pdf" src={pdfIcon} style={{
                                                                    height: 40, border: 0,
                                                                }}/>
                                                            </a>
                                                        </div>
                                                        <div className="col">
                                                            <div
                                                                className="kt-widget__caption">{locales_es.prescriptionDate}:
                                                            </div>
                                                            {prescription.date && <span className="kt-widget__value"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: dateTimeService.parseEventDate(dateTimeService.parseAPIStringToDate(prescription.date), true)
                                                                }}/>}
                                                        </div>
                                                    </div>
                                                    <div className="kt-widget__progress mb-3 w-100">
                                                        {renderMedicationItem(prescription)}
                                                    </div>
                                                    <br/>
                                                    {(userType === USER_TYPE_MEDIC || userType === USER_TYPE_SECRETARY) &&
                                                        <div className="kt-widget__actions clear">
                                                            <a onClick={(e) => {
                                                                e.preventDefault();
                                                                loadPrescription(prescription);
                                                            }}
                                                                className={`btn ${prescription.payment_status === PAYMENT_STATUS_PENDING ? 'btn-warning' : 'btn-success'} btn-sm btn-bold btn-upper text-white cursor-pointer m-1`}>{locales_es.seePrescription}</a>
                                                            <a onClick={(e) => {
                                                                e.preventDefault();
                                                                repeatPrescription(prescription);
                                                            }}
                                                                className="btn btn-primary btn-sm btn-bold btn-upper text-white cursor-pointer">{locales_es.repeatPrescription}</a>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                            {refreshing && <Loading/>}
                        </div>
                    </> : <>
                        <div className="row">
                            <div className="col">
                                <div className="kt-portlet kt-portlet--height-fluid overflow-auto">
                                    <table className="woopi-table">
                                        <thead>
                                        <tr className="text-center">
                                            <th><span>{locales_es.nameAndLastname}</span></th>
                                            <th><span>{locales_es.prescriptionDate}</span></th>
                                            <th><span>{locales_es.medications}</span></th>
                                            <th><span>{locales_es.actions}</span></th>
                                        </tr>
                                        </thead>
                                        <tbody className="kt-datatable__body ps ps--active-y">
                                        {prescriptions.map((prescription, index) => {
                                            return (<tr key={`p-${prescription.id}-${index}`} className="">
                                                <td>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__pic">
                                                            <img src={prescription.patient?.full_profile_image || ProfileImageDefault}
                                                                alt={locales_es.profileImage}/>
                                                        </div>
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__name">{prescription.patient?.name || prescription.patient?.lastname ? `${prescription.patient?.name} ${prescription.patient?.lastname}` : locales_es.deletedPatient}</span>
                                                            <span className="kt-user-card-v2__email">{locales_es.createdPrescription}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a className="cursor-pointer" onClick={(e) => {
                                                        e.preventDefault();
                                                        loadPrescription(prescription);
                                                    }}>
                                                        <img alt="pdf" src={pdfIcon} style={{
                                                            height: 30, float: 'left', marginRight: 10
                                                        }}/>
                                                        {prescription.date ?
                                                            <span className="kt-font-bold kt-widget__value"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: dateTimeService.parseEventDate(dateTimeService.parseAPIStringToDate(prescription.date), true)
                                                                }}/> : locales_es.notDefined}
                                                    </a>
                                                </td>
                                                <td>
                                                    {renderMedicationItem(prescription)}
                                                </td>
                                                <td className="text-right">
                                                    {/* @WOOPI TODO - OCULTAMOS EL BOTON DELFAR CON d-none por ahora*/}
                                                    <span className="d-none">
                                                    {!isPrescriptionExpired(prescription.date) ? (
                                                        <>
                                                            {prescription.medical_prescription_delivery_request?.embed_url ? (
                                                                <button
                                                                    onClick={() => handleDelfarEmbedUrl(prescription.medical_prescription_delivery_request.embed_url)}
                                                                    className="btn btn-accent btn-sm btn-bold btn-upper text-white cursor-pointer m-1"
                                                                    title={locales_es.myPrescriptionsCheckout.buttonViewCheckoutRecipe}>
                                                                    {locales_es.myPrescriptionsCheckout.buttonViewCheckoutRecipe}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    onClick={() => handleRecipeCheckoutAddresses(prescription.id)}
                                                                    className="btn btn-accent btn-sm btn-bold btn-upper text-white cursor-pointer m-1"
                                                                    title={locales_es.myPrescriptionsCheckout.buttonCheckoutRecipe}>
                                                                    {locales_es.myPrescriptionsCheckout.buttonCheckoutRecipe}
                                                                </button>)
                                                            }
                                                        </>
                                                    ) : (
                                                        <a className={`btn btn-warning btn-sm btn-bold btn-upper text-white cursor-pointer m-1 pl-2 pr-1`}>
                                                            <CustomTooltip
                                                                text={locales_es.myPrescriptionsCheckout.noAddressSelected}
                                                                position="top"
                                                                extraStyle={{
                                                                    textTransform: 'none',
                                                                    fontSize: '0.8rem',
                                                                    color: '#ffffff',
                                                                    backgroundColor: '#eea200'
                                                                }}
                                                            >
                                                                <i className="fa fa-exclamation-triangle"></i>
                                                            </CustomTooltip>
                                                        </a>)}
                                                        </span>
                                                    <a
                                                        className={`btn ${prescription.payment_status === PAYMENT_STATUS_PENDING ? 'btn-warning' : 'btn-success'} btn-sm btn-bold btn-upper text-white cursor-pointer m-1`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            loadPrescription(prescription);
                                                        }}
                                                    >
                                                        {locales_es.seePrescription}
                                                    </a>
                                                </td>
                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                    {pagingData && <TablePaging pagingData={pagingData}
                                        query={""}
                                        onChange={(params, pageUrl) => onChangeTablePaging(params, pageUrl)}
                                    />}
                                </div>
                            </div>
                        </div>
                    </>}

                </div>
            </div> : <div className="text-center">
                <h3>No tienes recetas aún.</h3>
            </div>}
        </>
    </>)
}

export default MyPrescriptionsPage;
