import React, {useEffect, useState} from 'react';
import './styles.css';
import Helpers from "../../modules/helpers";
import locales_es from '../../locales/es';
import APIService from "../../modules/apiService";
import Loading from "../loading";
import Spinner from "../spinner";
import mercadopagoLogo from '../../images/mercadopago_ars.webp';
import {
  HREF_PAGE_VIEW_PRESCRIPTION_HASH
} from "../../models/constants";
import {RWebShare} from "react-web-share";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const ChargePrescriptions = ({onClose, showMainModal, emails, cellphone, documents}) => {
  const api = new APIService();
  const helpers = new Helpers();
  const [email, setEmail] = useState(emails && emails.length ? emails[0] : '');
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(''); // '' or 'email' or 'link'
  const [amount, setAmount] = useState('');
  const [mercadoPagoEnabled, setMercadoPagoEnabled] = useState(null);
  const [congratsHashUrl, setCongratsHashUrl] = useState(false);

  const handleSend = () => {
    if (!Number(amount)) {
      showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeWithAnAmount);
      return;
    }

    if (mode === 'email' && !helpers.validateEmail(email)) {
      showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeWithAValidEmail);
      return;
    }

    setLoading(true);
    api
      .putMedicalPrescriptionPayment(documents[0].prescriptionId, {
        payment_amount: amount,
        notification_email: mode === 'email' ? email : null
      })
      .then((res) => {
        setLoading(false);
        // showMainModal(locales_es.successModal.title, res.message);
        setCongratsHashUrl(`${window.location.origin}${HREF_PAGE_VIEW_PRESCRIPTION_HASH}/${res.data.hash}`)
        window.initPopovers();
      })
      .catch((err) => {
        showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        setLoading(false);
      });
  };

  const getMercadoPagoStatus = () => {
    api.getMercadoPagoInfo().then(() => {
      setMercadoPagoEnabled(true);
    }).catch(() => {
      setMercadoPagoEnabled(false);
    })
  }

  const onClickLinkMercadoPago = () => {
    api.getMercadoPagoAuthorizationLink(window.location.origin).then(res => {
      window.location.href = res.data.url;
      onClose();
      // window.open(res.data.url, '_blank');
    }).catch(err => {
      showMainModal(locales_es.infoModal.title, helpers.getErrorMsg(err));
      onClose();
    })
  };

  useEffect(() => {
    getMercadoPagoStatus();
    // Cargar importe desde localStorage al montar el componente
    const savedAmount = localStorage.getItem('chargeAmount');
    if (savedAmount) {
      setAmount(savedAmount);
    }
  }, []);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    localStorage.setItem('chargeAmount', newAmount); // Guardar importe en localStorage
  };

  return (<div className="email-overlay">
    {loading ? <Loading/> : null}
    {congratsHashUrl ?
      <div className="email-overlay-content" style={{width: '90%'}}>
        <h3 className="mt-3 mb-3">¡Éxito!</h3>
        <p
          className="p-3">{mode === 'email' ? 'Le hemos enviado un correo a tu paciente para que abone y pueda ver su receta, pero por si acaso, te damos el enlace para que puedas compartirlo con el paciente de ser necesario.' :
          'Aquí tienes el enlace que necesitas: enviáselo al paciente para que pueda abonar y ver su receta'}</p>
        <CustomTooltip
          containerStyle={{width: '100%', textOverflow: 'ellipsis'}}
          trigger="click"
          text={locales_es.copiedToClipboard}
          position="top"
          timeout="2000"
        >
          <a onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(congratsHashUrl);
          }} className="btn btn-outline-hover-info btn-elevate btn-pill allow-user-select cursor-pointer" style={{
            textOverflow: 'ellipsis',
            width: '100%',
            display: 'inline-block',
            overflow: 'hidden'
          }}>{congratsHashUrl}</a>
        </CustomTooltip>
        <div className="text-center mt-3">
          <a
            href={`https://wa.me/${cellphone ? cellphone.replace(/\s+/g, '') : ''}?text=Aquí están sus prescripciones ${congratsHashUrl}`}
            target="_blank" className="btn btn-outline-brand btn-elevate btn-pill"><i
            class="la la-whatsapp"></i>{locales_es.whatsappShareButtonText}</a>
        </div>

        <div className="text-center mt-3">
          <RWebShare
            closeText={locales_es.close}
            data={{
              text: 'Aquí están sus prescripciones',
              url: congratsHashUrl,
              title: 'Link de cobro',
            }}
            onClick={() => console.log('Compartido exitosamente')}
          >
            <button className="btn btn-outline-accent btn-elevate btn-pill"><i
              class="la la-send"></i>{locales_es.rnShareButtonText}</button>
          </RWebShare>
        </div>
        <button onClick={() => {
          window.$('[data-toggle="popover"]').popover('hide');
          onClose();
        }} className="btn btn-outline-danger btn-pill mt-3">{locales_es.close}</button>
      </div>
      :
      mercadoPagoEnabled === null ? <Spinner/> :
        mercadoPagoEnabled ?
          mode === '' ?
            <div className="email-overlay-content">
              <h3 className="mt-3 mb-3">Cobrar por e-mail o por link</h3>
              <p className="p-3">Elige si quieres enviarle un botón de pago a tu paciente por e-mail, o si prefieres
                generar un link de pago y enviárselo por tu cuenta</p>
              <div className="row">
                <div className="col cursor-pointer" onClick={() => setMode('email')}>
                  <div class="kt-notification-v2__item-icon kt-bg-light-info">
                    <i class="fa fa-envelope kt-font-info"></i>
                  </div>
                  <button className="mt-3 btn btn-outline-info btn-pill cursor-pointer">Botón por E-mail</button>
                </div>
                <div className="col cursor-pointer" onClick={() => setMode('link')}>
                  <div class="kt-notification-v2__item-icon kt-bg-light-focus">
                    <i class="fa fa-link kt-font-focus"></i>
                  </div>
                  <button className="mt-3 btn btn-outline-focus btn-pill cursor-pointer">Link de Pago</button>
                </div>
              </div>
              <div className="email-overlay-actions">
                <button onClick={onClose} className="btn btn-outline-danger btn-pill">{locales_es.close}</button>
              </div>
            </div>
            : mode === 'email' ?
              <div className="email-overlay-content">
                <div class="kt-notification-v2__item-icon kt-notification-v text-center">
                  <i class="fa fa-envelope kt-font-info"></i>
                </div>
                <h3 className="mt-3 mb-3">Enviar Botón por Email</h3>
                <p className="p-3">Ingresa el monto y el e-mail del paciente para que le enviemos las instrucciones de
                  pago. También te daremos el link de pago para que lo tengas vos.</p>
                <div className="row">
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend"><span className="input-group-text pl-3 pr-3">&nbsp;$</span>
                      </div>
                      <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={locales_es.completeWithAnAmountToCharge}
                        className="form-control"
                      />
                    </div>
                    <div className="input-group">
                      <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={locales_es.email_address}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="email-overlay-actions">
                  <button onClick={handleSend} className="btn btn-success btn-elevate btn-pill">Enviar</button>
                  <button onClick={() => setMode('')}
                          className="btn btn-outline-danger btn-pill">{locales_es.cancel}</button>
                </div>
              </div>
              :
              <div className="email-overlay-content">
                <div class="kt-notification-v2__item-icon kt-notification-v text-center">
                  <i class="fa fa-link kt-font-focus"></i>
                </div>
                <h3 className="mt-3 mb-3">Link de Pago</h3>
                <p className="p-3">Ingresa el monto y te generaremos un link de pago para que se lo envíes al paciente por
                  el medio que quieras.</p>
                <div className="row">
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend"><span className="input-group-text pl-3 pr-3">&nbsp;$</span>
                      </div>
                      <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={locales_es.completeWithAnAmountToCharge}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="email-overlay-actions">
                  <button onClick={handleSend} className="btn btn-success btn-elevate btn-pill">Crear Link</button>
                  <button onClick={() => setMode('')}
                          className="btn btn-outline-danger btn-pill">{locales_es.cancel}</button>
                </div>
              </div>
          :
          <div className="email-overlay-content">
            <div class="kt-notification-v2__item-icon text-center">
              <img src={mercadopagoLogo} class="flaticon-cart kt-font-success w-100 p-3"></img>
            </div>
            <h3 className="mt-3 mb-3">No tienes Mercado Pago</h3>
            <p className="p-3">Debes primero vincular tu cuenta de Mercado Pago primero para poder hacer el cobro de tu
              receta</p>
            <div className="email-overlay-actions">
              <button onClick={onClickLinkMercadoPago} className="btn btn-success btn-elevate btn-pill">Vincular
                cuenta
              </button>
            </div>
            <div className="email-overlay-actions">
              <button onClick={onClose} className="btn btn-outline-danger btn-pill">Cerrar</button>
            </div>
          </div>
    }
  </div>);
};

export default ChargePrescriptions;
