import {createContext, useState, useEffect, useContext} from 'react';
import {HREF_PAGE_PATIENT_ADDRESSES, MODAL_TYPE} from "../models/constants";
import locales_es from "../locales/es";
import APIService from '../modules/apiService';
import {ModalContext} from "./ModalContext";
import Helpers from "../modules/helpers";

export const InitialAddressState = createContext(null);

export const AddressContext = createContext(InitialAddressState);

export const useAddressContext = () => {
    const context = useContext(AddressContext);
    if (!context) {
        throw new Error('useAddressContext must be used within a AddressProvider');
    }
    return context;
};

export const AddressProvider = ({children}) => {
    const api = new APIService();

    const {dispatch} = useContext(ModalContext);
    const [isLoadingAddress, setIsLoadingAddress] = useState(true);
    const [hasLoadedAddressData, setHasLoadedAddressData] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [addressData, setAddressData] = useState(null);
    const [addressDefaultLocationMarker, setAddressDefaultLocationMarker] = useState(null);
    const helpers = new Helpers();

    const showModalConfirmation = (titleModal, resultType = "success" || "failed", isAcceptAction = true) => {
        dispatch({
            type: "OPEN_MODAL",
            modalName: "updateAddressResult",
            modalType: MODAL_TYPE.RESULT,
            payload: {
                result: resultType,
                titleSuccess: titleModal,
                titleFailed: titleModal,
                acceptAction: () => {
                    if (isAcceptAction) {
                        window.location.href = HREF_PAGE_PATIENT_ADDRESSES;
                    }
                }
            }
        });
    }

    const getMyAddresses = async () => {
        setIsLoadingAddress(true);
        try {
            const response = await api.getMyAddresses();
            if (response.data) {
                setAddresses(response.data);
            }
        } catch (error) {
            console.error("Error al obtener las direcciones:", error);
        } finally {
            setIsLoadingAddress(false);
        }
    };

    const getAddressById = async (addressId) => {
        setIsLoadingAddress(true);
        setHasLoadedAddressData(false);
        try {
            const response = await api.getAddressesById(addressId);
            if (response.data) {
                setAddressData(response.data);
                setAddressDefaultLocationMarker({
                    lat: Number(response.data.lat),
                    lng: Number(response.data.lng)
                });
                setHasLoadedAddressData(true);
            }
        } catch (error) {
            console.error("Error al obtener la dirección:", error);
        } finally {
            setIsLoadingAddress(false);
        }
    };

    const createAddress = async (formData) => {
        setIsLoadingAddress(true);
        try {
            const response = await api.postAddress(formData);
            if (response.data) {
                await getMyAddresses();
                showModalConfirmation(locales_es.userAddresses.modalAddAddressMessage, "success");
            }
        } catch (error) {
            console.error(locales_es.userAddresses.modalAddAddressMessageError, error);
            showModalConfirmation(helpers.getErrorMsg(error), "failed");
        } finally {
            setIsLoadingAddress(false);
        }
    };

    const updateAddress = async (addressId, formData) => {
        setIsLoadingAddress(true);
        try {
            const response = await api.putAddress(addressId, formData);
            if (response.data) {
                await getMyAddresses();
                if (addressData && addressData.id === addressId) {
                    setAddressData(response.data);
                }
                showModalConfirmation(locales_es.userAddresses.modalUpdateAddressMessage, "success");
            }
        } catch (error) {
            console.error(locales_es.userAddresses.modalUpdateAddressMessageError, error);
            showModalConfirmation(helpers.getErrorMsg(error), "failed");
        } finally {
            setIsLoadingAddress(false);
        }
    };

    const deleteAddress = async (addressId) => {
        setIsLoadingAddress(true);
        try {
            const response = await api.deleteAddress(addressId);
            if (response) {
                await getMyAddresses();
                showModalConfirmation(locales_es.userAddresses.modalDeleteAddressMessageConfirmation, "success", false);
            }
        } catch (error) {
            console.error(locales_es.userAddresses.modalDeleteAddressMessageConfirmationError, error);
            showModalConfirmation(helpers.getErrorMsg(error), "failed");
        } finally {
            setIsLoadingAddress(false);
        }
    };

    useEffect(() => {
        getMyAddresses();
    }, []);

    const value = {
        addresses,
        addressData,
        addressDefaultLocationMarker,
        isLoadingAddress,
        hasLoadedAddressData,
        getMyAddresses,
        getAddressById,
        createAddress,
        updateAddress,
        deleteAddress,
    }

    return (
        <AddressContext.Provider value={value}>
            {children}
        </AddressContext.Provider>
    );
};
