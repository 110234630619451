import React, { useEffect, useState } from 'react';
import '../ChargePrescriptions/styles.css';
import Helpers from "../../modules/helpers";
import locales_es from '../../locales/es';
import APIService from "../../modules/apiService";
import { MEDICAL_PRESCRIPTION_TYPE, PRESCRIPTION_CERTIFICATE_TYPE } from "../../models/constants";
import Loading from "../loading";

const SendEmailPrescriptions = ({ onClose, showMainModal, prescriptionType, emails, documents }) => {
  const api = new APIService();
  const helpers = new Helpers();
  const [email, setEmail] = useState('');
  const [additionalEmails, setAdditionalEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddEmail = () => {
    if (email) {
      if (helpers.validateEmail(email)) {
        setAdditionalEmails([...additionalEmails, email]);
        setEmail('');
      } else {
        showMainModal(locales_es.errorModal.title, locales_es.register.errorEmail);
      }
    }
  };

  const handleRemoveEmail = (indexToRemove) => {
    setAdditionalEmails(additionalEmails.filter((_, index) => index !== indexToRemove));
  };

  const handleSend = () => {
    let parsedEmails = [...additionalEmails];
    if (email && helpers.validateEmail(email)) {
      parsedEmails.push(email);
    } else if (email && !helpers.validateEmail(email)) {
      showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeWithAValidEmail);
      return;
    }

    if (!parsedEmails.length) {
      showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeWithAtLeastOneEmail);
      return;
    }

    setLoading(true);

    const request = prescriptionType === MEDICAL_PRESCRIPTION_TYPE
      ? api.postPrescriptionEmail(documents, { emails: parsedEmails })
      : prescriptionType === PRESCRIPTION_CERTIFICATE_TYPE
      ? api.postCertificateEmail(documents, { emails: parsedEmails })
      : Promise.reject(new Error(locales_es.anErrorHasOcurred));

    request
      .then((res) => {
        setLoading(false);
        showMainModal(locales_es.successModal.title, res.message);
        onClose();
      })
      .catch((err) => {
        showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (emails && emails.length > 0) {
      setAdditionalEmails(emails);
    }
  }, [emails]);

  return (
    <div className="email-overlay">
      {loading ? <Loading /> : null}
      <div className="email-overlay-content">
        <div className="kt-notification-v2__item-icon text-center">
          <i className="flaticon-paper-plane-1 kt-font-success"></i>
        </div>
        <h3 className="mt-3 mb-3">{locales_es.sendEmailPrescriptionsTitle}</h3>
        <p className="p-3">{locales_es.sendEmailPrescriptionsSubtitle}</p>
        <div className="email-input-group input-group">
          <div className="input-group-prepend"><span className="input-group-text">@</span></div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={locales_es.enterAnEmail}
            className="email-input form-control"
          />
          <button onClick={handleAddEmail} type="button" className="btn btn-outline-brand btn-elevate btn-icon">
            <i className="la la-plus"></i>
          </button>
        </div>
        {additionalEmails.length ? additionalEmails.map((addedEmail, index) => (
          <div key={index} className="added-email">{addedEmail}
            <button
              type="button"
              onClick={() => handleRemoveEmail(index)}
              className="added-email-remove-btn btn btn-outline-hover-danger btn-sm btn-icon btn-circle m-1">
              <i className="fa fa-times"></i>
            </button>
          </div>
        )) : (
          <div className="alert alert-secondary" role="alert">
            <div className="alert-icon"><i className="flaticon-email kt-font-brand"></i></div>
            <div className="alert-text">{locales_es.sendEmailPrescriptionsEmailArrayInfo}</div>
          </div>
        )}
        <div className="email-overlay-actions">
          <button onClick={handleSend} className="btn btn-success btn-elevate btn-pill">{locales_es.send}</button>
          <button onClick={onClose} className="btn btn-outline-danger btn-pill">{locales_es.cancel}</button>
        </div>
      </div>
    </div>
  );
};

export default SendEmailPrescriptions;
