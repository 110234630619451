import React from "react";

const StaticAlert = ({text}) => {
  return (
    <div className="alert alert-metal fade show mt-3" role="alert">
      <div className="alert-icon">
        <i className="flaticon2-group"></i>
      </div>
      <div className="alert-text">
        {text}
      </div>
      <div className="alert-close">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">
            <i className="la la-close"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default StaticAlert;
