import React, {Component} from 'react';
import locales_es, {WEEKDAYS_LONG} from "../../locales/es";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import {
  DAYS_OF_THE_WEEK,
  FC_SLOT_MAX_TIME,
  FC_SLOT_MIN_TIME, HREF_PAGE_SETTINGS,
  HREF_PAGE_MEDIC_EDIT_TIMETABLES,
  hrefDashboard, PLAN_TAGS,
  USER_TYPE_MEDIC,
  USER_TYPE_SECRETARY
} from "../../models/constants";

import Loading from './../../components/loading';
import Modal from "../modal";
import ConfigService from "../../modules/configService";
import Spinner from "../spinner";
import AuthService from "../../modules/authService";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import TimetablesList from "../timetablesList";
import {SubscriptionPlanContext} from "../../context/SubscriptionPlanContext";
import StaticAlert from "../StaticAlert/StaticAlert";

export default class MedicTimetables extends Component {
  static contextType = SubscriptionPlanContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      clinics: null,
      clinicId: null,
      consultingRoomOptions: [{
        id: 0
      }],
      duration: 15,
      value: {
        start: FC_SLOT_MIN_TIME,
        end: FC_SLOT_MAX_TIME
      },
      enabled: true,
      modalVisible: false,
      modalDeleteClinicVisible: false,
      selectedTimetableId: 0,
      footer_email_text: '',
      interruptedAgenda: this.props.medic && this.props.medic.interrupted_agenda ? this.props.medic.interrupted_agenda : '',
      appointmentTypes: [],
      appointmentTypeId: null,
      editionDisabled: false,
      timetables: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.viewHelpers = new ViewHelpers();
    this.auth = new AuthService();
    this.configService = new ConfigService();
    this.dateTimeService = new DateTimeService();
  }

  componentDidMount() {
    if (this.props.userType === USER_TYPE_MEDIC) {
      this.loadMedicClinics();
    } else if (this.props.userType === USER_TYPE_SECRETARY) {
      this.loadSecretaryClinic();
    } else {
      window.location.href = hrefDashboard;
    }

    this.api.getAppointmentsTypes().then(res => {
      this.setState({
        appointmentTypes: res.data,
        appointmentTypeId: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  loadMedicClinics() {
    this.api.getMyClinics().then(res => {
      this.setState({
        clinics: res.data
      }, () => {
        this.setMedicDefaults();
      });
      this.getTimetables();
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  setMedicDefaults() {
    this.setState({
      clinicId: this.state.clinics && this.state.clinics.length ? this.state.clinics[0].id : null,
      consulting_room_id: this.state.consultingRoomOptions && this.state.consultingRoomOptions.length ? this.state.consultingRoomOptions[0].id : null,
      day: DAYS_OF_THE_WEEK[0].value
    });
  }

  loadSecretaryClinic() {
    this.configService.getLocalClinicData().then(clinic => {
      this.setState({
        clinicId: clinic.id
      }, () => {
        this.api.getConsultingRooms({clinic_id: this.state.clinicId}).then(res => {
          if (res && res.data && res.data.length) {
            this.setState({
              consultingRoomOptions: res.data
            }, () => {
              this.setSecretaryDefaults();
              this.getTimetables();
            });
          } else {
            this.setSecretaryDefaults();
            this.getTimetables();
          }
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      });
    }).catch(err => {
      console.log(err);
    });
  }

  setSecretaryDefaults() {
    this.setState({
      consulting_room_id: this.state.consultingRoomOptions[0].id,
      day: DAYS_OF_THE_WEEK[0].value
    });
  }

  getTimetables() {
    this.setLoading(true);
    const objData = {
      medic_id: this.props.medic.id
    };

    if (this.props.userType === USER_TYPE_SECRETARY) {
      objData.clinic_id = this.state.clinicId
    }

    this.api.getTimetables(objData).then(res => {
      this.setState({
        timetables: res.data,
      });
      this.setLoading(false);
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setLoading(false);
    })
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    });
  }

  confirmEventRemove(timetableId) {
    if (this.state.editionDisabled) {
      this.props.showMainModal(locales_es.infoModal.title, locales_es.timetableDeleteDisabled);
      return;
    }
    const confirm = window.confirm(locales_es.confirmLapseTimeRemoval);
    if (confirm) {
      this.api.deleteTimetables(timetableId).then(res => {
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.setState({
          timetableId: 0,
          modalVisible: 0,
        }, () => this.getTimetables());
      }).catch(err => {
        this.props.showMainModal(locales_es.infoModal.title, this.helpers.getErrorMsg(err));
        this.setState({
          timetableId: 0,
          modalVisible: 0,
        }, () => this.getTimetables());
      });
    }
  }

  checkEditionDisable() {
    if (this.props.userType === USER_TYPE_MEDIC) {
      this.api.getMyClinics().then(res => {
        const selectedTimetable = this.state.timetables.filter(tt => Number(tt.id) === Number(this.state.selectedTimetableId));
        let selectedClinic = [];
        if (selectedTimetable.length) {
          selectedClinic = res.data.filter(clinic => Number(clinic.id) === Number(selectedTimetable[0].clinic_id));
        }
        this.setState({
          editionDisabled: !selectedClinic.length
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setState({
          editionDisabled: true, // for safe reasons...
        });
      })
    }
  }

  setSelectedTimetableIdAndOpenModal(timetableId) {
    this.setState({
      selectedTimetableId: timetableId,
      modalVisible: true,
    });
    this.checkEditionDisable(timetableId);
  }

  renderSelectedTimetableInfo() {
    if (!this.state.selectedTimetableId) {
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.unexpectedError);
      return;
    }

    const timetables = JSON.parse(JSON.stringify(this.state.timetables));
    const selectedTimetable = timetables.filter(tt => Number(tt.id) === Number(this.state.selectedTimetableId))[0];
    const selectedTimetableEnabled = Boolean(selectedTimetable.enabled);

    return (
      <div>
        <p>{locales_es.day}: {WEEKDAYS_LONG[selectedTimetable.day]}</p>
        <p>{locales_es.from}: {selectedTimetable.start}</p>
        <p>{locales_es.to}: {selectedTimetable.end}</p>
        <p>{locales_es.price}: {this.viewHelpers.getTimetablePricesText(selectedTimetable)}</p>
        {selectedTimetable.comment &&
          <p>{locales_es.observations}: {selectedTimetable.comment}</p>
        }
        {selectedTimetable.start_date &&
          <p>{locales_es.startDate}: {this.dateTimeService.parseDateToConventionalAPIString(new window.Date(selectedTimetable.start_date))}</p>
        }
        {selectedTimetable.end_date &&
          <p>{locales_es.endDate}: {this.dateTimeService.parseDateToConventionalAPIString(new window.Date(selectedTimetable.end_date))}</p>
        }
        <strong><i
          className={selectedTimetableEnabled ? 'flaticon2-check-mark kt-font-success' : 'flaticon2-cross kt-font-danger'}/> {selectedTimetableEnabled ? locales_es.enabled : locales_es.disabled}
        </strong>
      </div>
    )
  }

  goToEditTimetable(timetableId, clone) {
    if (this.state.editionDisabled) {
      this.props.showMainModal(locales_es.infoModal.title, locales_es.timetableEditionDisabled);
      return;
    }
    let url = `${HREF_PAGE_MEDIC_EDIT_TIMETABLES}/${this.props.medic.id}/${timetableId}`;
    if (clone) {
      url += '?clone=true';
    }
    window.location.href = url;
  }

  render() {
    const {clinics, timetables} = this.state;
    const {subscriptionPlan} = this.context;

    return (
      <>
        {this.state.loading ? <Loading/> : null}
        {this.props.userType === USER_TYPE_MEDIC && clinics === null ? <Spinner/> :
          clinics && clinics.length || this.props.userType === USER_TYPE_SECRETARY ?
            <div className="kt-portlet">
              {timetables === null ? <Spinner/> : timetables && timetables.length ?
                <TimetablesList timetables={timetables}
                                clinics={clinics}
                                refresh={() => this.getTimetables()}
                                showMainModal={this.props.showMainModal}
                                onViewTimetable={(timetableId) => {
                                  this.setSelectedTimetableIdAndOpenModal(timetableId);
                                }}
                                onDuplicateTimetable={(timetableId) => {
                                  this.goToEditTimetable(timetableId, true);
                                }}

                /> :
                <div className="text-center">
                  <h4>No se encontraron franjas de atención</h4>
                </div>
              }
            </div>
            :
            <div className="kt-portlet p-3 mt-3">
              <div className="alert alert-warning fade show mt-3" role="alert">
                <div className="alert-icon"><i className="flaticon-warning"></i></div>
                <div className="alert-text">No tienes lugares de atención. Ingresa al menos uno para configurar tus
                  turnos.
                </div>
                <div className="alert-close">
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i className="la la-close"></i></span>
                  </button>
                </div>
              </div>
              <div className="text-center">
                <a href={`${HREF_PAGE_SETTINGS}#tab4`}
                   className="btn btn-brand btn-sm btn-bold btn-upper">+ {locales_es.addClinic}</a>
              </div>
            </div>
        }

        {this.auth.getLocalUserType() === USER_TYPE_MEDIC && subscriptionPlan && subscriptionPlan.tag === PLAN_TAGS.ENTERPRISE &&
          <StaticAlert text={locales_es.teamWarningMedicTimetablesStaticAlertText} />
        }

        {this.state.modalVisible ?
          <Modal onClose={() => this.setState({modalVisible: false})}
                 modalId="editTimetable"
                 visible={this.state.modalVisible}
                 title={locales_es.overviewTimetable}
                 actions={[
                   {
                     title: locales_es.deleteTimetable,
                     onClick: () => this.confirmEventRemove(this.state.selectedTimetableId),
                     className: 'btn btn-danger',
                   },
                   {
                     title: locales_es.editTimetable,
                     onClick: () => this.goToEditTimetable(this.state.selectedTimetableId),
                     className: 'btn btn-outline-info',
                   },
                   {
                     title: locales_es.cancel,
                     onClick: () => this.setState({
                       selectedTimetableId: 0,
                       modalVisible: false,
                     }),
                     className: 'btn btn-outline-brand',
                   }
                 ]}
          >
            {this.renderSelectedTimetableInfo()}
          </Modal>
          : null}
      </>
    )
  }
}
