import React, {Component, createRef} from 'react';
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import {langCode} from '../../locales/es';
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
import IMask from 'imask';
import DateTimeService from "../../modules/DateTimeService";

registerLocale(langCode, es);

export default class FormDatePicker extends Component {
  constructor(props) {
    super(props);

    this.datePickerRef = createRef();
    this.mask = null;

    // Instancia de DateTimeService
    this.dateTimeService = new DateTimeService();
  }

  componentDidMount() {
    if (this.datePickerRef.current?.input) {
      this.mask = IMask(this.datePickerRef.current.input, this.props.type === 'time' ?
        {
          mask: 'HH:mm',
          blocks: {
            HH: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 23,
              maxLength: 2,
            },
            mm: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 59,
              maxLength: 2,
            }
          },
          format: function (date) {
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
          },
          parse: function (str) {
            const [hours, minutes] = str.split(':');
            return new Date(1970, 0, 1, hours, minutes);
          },
        } : this.props.type === 'datetime'
          ? {
            mask: Date,
            pattern: 'd/`m/`Y H:`M',
            blocks: {
              d: {mask: IMask.MaskedRange, from: 1, to: 31, maxLength: 2},
              m: {mask: IMask.MaskedRange, from: 1, to: 12, maxLength: 2},
              Y: {mask: IMask.MaskedRange, from: 1900, to: 9999},
              H: {mask: IMask.MaskedRange, from: 0, to: 23, maxLength: 2},
              M: {mask: IMask.MaskedRange, from: 0, to: 59, maxLength: 2},
            },
            format: (date) => {
              let day = date.getDate().toString().padStart(2, '0');
              let month = (date.getMonth() + 1).toString().padStart(2, '0');
              let year = date.getFullYear();
              let hours = date.getHours().toString().padStart(2, '0');
              let minutes = date.getMinutes().toString().padStart(2, '0');
              return `${day}/${month}/${year} ${hours}:${minutes}`;
            },
            parse: (str) => {
              const [dateStr, timeStr] = str.split(' ');
              const [day, month, year] = dateStr.split('/');
              const [hours, minutes] = timeStr.split(':');
              return new Date(year, month - 1, day, hours, minutes);
            },
          }
          : {
            mask: Date,
            pattern: 'd/m/`Y',
            format: (date) => {
              let day = date.getDate().toString().padStart(2, '0');
              let month = (date.getMonth() + 1).toString().padStart(2, '0');
              let year = date.getFullYear();
              return `${day}/${month}/${year}`;
            },
            parse: (str) => {
              const [day, month, year] = str.split('/');
              return new Date(year, month - 1, day);
            },
          }
      );
    }
  }

  handleDateChange = (date) => {
    const {type, handleChange} = this.props;

    if (!date) {
      if (type === 'time') {
        handleChange(null); // Asegura que no intenta formatear un valor vacío
        return;
      } else {
        // Si el usuario borra el input, ponemos un valor por defecto
        const defaultDate = new Date();
        defaultDate.setMinutes(0);
        defaultDate.setHours(defaultDate.getHours() + 1);
        handleChange(this.dateTimeService.formatDateTimeToString(defaultDate));
        return;
      }
    }

    if (type === 'time') {
      const formattedTime = this.dateTimeService.formatTimeToHHMM(date);
      handleChange(formattedTime);
    } else if (type === 'datetime') {
      handleChange(date);
    } else {
      handleChange(date);
    }
  };


  render() {
    const {
      id,
      minDate,
      maxDate,
      value,
      required,
      disabled,
      placeholder,
      autoComplete,
      customClassName,
      wrapperCustomClassName,
      type
    } = this.props;

    const defaultClassName = 'form-control datePicker ';
    const className = customClassName ? (defaultClassName + customClassName) : defaultClassName;
    const defaultWrapperClassName = 'form-group ';
    const wrapperClassName = wrapperCustomClassName ? (defaultWrapperClassName + wrapperCustomClassName) : defaultWrapperClassName;

    return (
      <div className={wrapperClassName}>
        {this.props.label && <label>{this.props.label} {this.props.required ? '*' : '(Opcional)'}</label>}
        <div className="input-group date">
          <DatePicker
            ref={this.datePickerRef}
            className={className}
            key={id}
            id={id}
            locale={langCode}
            selected={value ? (type === 'datetime' ? new Date(value) : (type === 'time' ? this.dateTimeService.parseHHMMToDate(value) : value)) : null}
            onChange={this.handleDateChange}
            disabled={disabled}
            required={required}
            placeholderText={placeholder}
            showTimeSelect={type === 'time' || type === 'datetime'}
            showTimeSelectOnly={type === 'time'}
            timeIntervals={5}
            timeFormat="HH:mm"
            dateFormat={type === 'datetime' ? "dd/MM/yyyy HH:mm" : (type === 'time' ? "HH:mm" : "dd/MM/yyyy")}
            minDate={minDate}
            maxDate={maxDate}
            autoComplete={autoComplete || "off"}
          />
        </div>
        {this.props.info && <span className="form-text text-muted">{this.props.info}</span>}
      </div>
    );
  }
}
