import {HREF_PAGE_PATIENT_ADDRESSES_ADD} from "../../models/constants";

const AddUserAddressButton = () => {
    return (
        <>
            <div className="text-center m-4">
                <a href={HREF_PAGE_PATIENT_ADDRESSES_ADD}
                    className="btn btn-brand btn-sm btn-bold btn-upper">+ Agregar Dirección</a>
            </div>
        </>
    )
}

export default AddUserAddressButton;
